import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule, deleteSchedule, activateSchedule, deactivateSchedule } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Switch } from '../../components/ui/switch';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from '../../components/ui/alert-dialog';
import EditScheduleForm from './EditScheduleForm';
import { useToast } from '../../components/ui/use-toast';
import AddToTeamButton from '../../components/AddToTeamButton';

const ScheduleDetails = ({ onUpdate }) => {
    const dispatch = useDispatch();
    const selectedSchedule = useSelector((state) => state.schedules.selectedSchedule);
    const [isActive, setIsActive] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const { toast } = useToast();

    useEffect(() => {
      if (selectedSchedule) {
        setIsActive(selectedSchedule.isActive);
        setIsEditing(false);
      }
    }, [selectedSchedule]);
  
    if (!selectedSchedule) {
      return <div>No schedule selected</div>;
    }
  
    const handleToggleActive = async () => {
      try {
        if (isActive) {
          await dispatch(deactivateSchedule(selectedSchedule._id)).unwrap();
        } else {
          await dispatch(activateSchedule(selectedSchedule._id)).unwrap();
        }
        setIsActive(!isActive);
        onUpdate(); // Call onUpdate to refresh the schedules list
    toast({
      title: `Schedule ${isActive ? 'deactivated' : 'activated'}`,
      description: `The schedule has been successfully ${isActive ? 'deactivated' : 'activated'}.`,
      variant: "success",
    });
      } catch (error) {
        //console.error('Failed to toggle schedule status:', error);
        toast({
          title: "Error",
          description: "Failed to toggle schedule status. Please try again.",
          variant: "destructive",
        });
      }
    };
  
    const handleDelete = async () => {
        try {
          await dispatch(deleteSchedule(selectedSchedule._id)).unwrap();
          setIsDeleteDialogOpen(false);
          onUpdate();
          toast({
            title: "Schedule deleted",
            description: "The schedule has been successfully deleted.",
            variant: "success",
          });
        } catch (error) {
          console.error('Failed to delete schedule:', error);
          toast({
            title: "Error",
            description: "Failed to delete the schedule. Please try again.",
            variant: "destructive",
          });
        }
      };
    
      const handleEdit = () => {
        setIsEditing(true);
      };
    
      const handleCancelEdit = () => {
        setIsEditing(false);
      };
    
      if (isEditing) {
        return <EditScheduleForm schedule={selectedSchedule} onCancel={handleCancelEdit} onUpdate={onUpdate} />;
      }
    
      return (
        <div>
          <h2 className="text-inter font-bold text-2xl mb-4">{selectedSchedule.flow.name} Schedule</h2>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div className='flex gap-4 justify-center items-center'>
                <span className={`font-semibold py-1 px-2 rounded ${isActive ? 'bg-green-400' : 'bg-red-400'}`}>Status: {isActive ? 'Active' : 'Inactive'}</span>
                <div className='flex flex-col items-center'>
                  <span className={`text-sm`}>{isActive ? 'Deactivate' : 'Activate'}</span>
                  <Switch
                      checked={isActive}
                      onCheckedChange={handleToggleActive}
                  />
                </div>
              </div>
                
              <div className='flex items-center gap-4'>
                <AddToTeamButton model='Schedule' _id={selectedSchedule._id} />
                
              </div>
              
            </div>
            {selectedSchedule.team && selectedSchedule.team.length > 0 && (
              <div>
              <span className="font-semibold">Teams:</span>{' '}
              <span className='capitalize'>
                {selectedSchedule.team.map((team, index) => (
                  <React.Fragment key={team.id || index}>
                    {index > 0 && ', '}
                    {team.name}
                  </React.Fragment>
                ))}
              </span>
            </div>
            )}
            
            
            <div>
              <span className="font-semibold">Type:</span> <span className='capitalize'>{selectedSchedule.type}</span>
            </div>
            <div>
              <span className="font-semibold">Timezone:</span> {selectedSchedule.timezone}
            </div>
            <div>
              <span className="font-semibold">Next Run Time:</span> {new Date(selectedSchedule.nextRunTime).toLocaleString()}
            </div>
            {selectedSchedule.lastRunTime && (
              <div>
                <span className="font-semibold">Last Run Time:</span> {new Date(selectedSchedule.lastRunTime).toLocaleString()}
              </div>
            )}
            <div>
              <span className="font-semibold">Run Count:</span> {selectedSchedule.runCount}
            </div>
            {selectedSchedule.maxRuns && (
              <div>
                <span className="font-semibold">Max Runs:</span> {selectedSchedule.maxRuns}
              </div>
            )}
            
            <Alert>
              <AlertTitle>Cron Expression</AlertTitle>
              <AlertDescription>{selectedSchedule.cronExpression}</AlertDescription>
            </Alert>
            <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={handleEdit}>Edit</Button>
            <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
              <AlertDialogTrigger asChild>
                <Button variant="destructive">Delete</Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure you want to delete this schedule?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete the schedule
                    and remove it from our servers.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
          </div>
        </div>
      );
    };
    
    export default ScheduleDetails;