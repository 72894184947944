// src/pages/ChatComponents/ChatInput.js

import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FileUpload } from '../../components/FileUpload';
import { FilePreview } from '../../components/FilePreview';

const ChatInput = ({
  message,
  setMessage,
  handleSendMessage,
  currentChat,
  selectedAssistant,
  chatTitle,
  files,
  setFiles,
  sentFiles
}) => {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleFileUpload = (uploadedFiles) => {
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles].slice(0, 5));
  };

  const removeFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  return (
    <form onSubmit={handleSendMessage} className="p-4 pb-0 border-t">
      <div className="flex items-center">
        <div className="w-1/5 mr-4">
          <FileUpload onFileUpload={handleFileUpload} />
          {files.length > 0 && (
            <div className="mt-2">
              {files.map((file, index) => (
                <FilePreview key={index} file={file} onRemove={removeFile} />
              ))}
            </div>
          )}
        </div>
        <div className="w-4/5 flex items-center gap-4">
          <Textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
            className="flex-grow mb-2"
            rows={3}
          />
          <Button
            type="submit"
            disabled={(!currentChat && (!selectedAssistant || !chatTitle)) || (!message.trim() && files.length === 0)}
            className="rounded-full w-10 h-10 flex items-center justify-center p-0"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4" />
            <span className="sr-only">Send</span>
          </Button>
        </div>
      </div>

      {/* Display the list of sent files under the input field */}
      {sentFiles && sentFiles.length > 0 && (
        <div className="mt-1 flex justify-end items-end max-w-4/5">
          <h4 className="text-sm font-medium mr-2">Previously Attached Files:</h4>
          <div className="flex flex-wrap gap-2">
            {sentFiles.map((file, index) => (
              <FilePreview key={index} file={file} />
            ))}
          </div>
        </div>
      )}
    </form>
  );
};

export default ChatInput;