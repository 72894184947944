import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchSchedules, setSelectedSchedule } from '../slices/schedulesSlice';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import ScheduleList from './SchedulingComponents/ScheduleList';
import ScheduleDetails from './SchedulingComponents/ScheduleDetails';
import CreateScheduleForm from './SchedulingComponents/CreateScheduleForm';
import { fetchFlows } from "../slices/flowsSlice";

const Scheduling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const { schedules, selectedSchedule, status, error } = useSelector((state) => state.schedules);
  const { selectedTeam } = useSelector((state) => state.teams);
  const { flows } = useSelector((state) => state.flows);

  const refreshSchedules = useCallback(() => {
    dispatch(fetchSchedules());
  }, [dispatch]);

  useEffect(() => {
    refreshSchedules();
  }, [refreshSchedules]);

  useEffect(() => {
    if (scheduleId) {
      const schedule = schedules.find((s) => s._id === scheduleId);
      dispatch(setSelectedSchedule(schedule));
    } else {
      dispatch(setSelectedSchedule(null));
    }
  }, [scheduleId, schedules, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchFlows({ teamId: selectedTeam })).unwrap(),
        ]);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [dispatch, selectedTeam]);

  const handleCreateSchedule = () => {
    navigate('/scheduling/create');
  };

  /*if (status === 'loading') {
    return <div className="text-center">Loading...</div>;
  }*/

  if (status === 'failed') {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="px-4 pb-8">
      <h1 className="font-bold text-2xl mb-6">Schedule Management</h1>
      <div className="flex gap-6">
        <div className="w-1/3">
          <Card className="p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-xl">Schedules</h2>
              <Button onClick={handleCreateSchedule}>Create Schedule</Button>
            </div>
            <ScheduleList schedules={schedules} selectedScheduleId={selectedSchedule?._id} />
          </Card>
        </div>
        <div className="w-2/3">
          <Card className="p-4">
            {scheduleId === 'create' ? (
              <CreateScheduleForm />
            ) : selectedSchedule ? (
              <ScheduleDetails schedule={selectedSchedule} onUpdate={refreshSchedules} />
            ) : (
              <div className="text-center text-gray-500">Select a schedule to view details</div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Scheduling;