import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login, register } from '../slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (isRegistering) {
        await dispatch(register({ email, password })).unwrap();
      } else {
        await dispatch(login({ email, password })).unwrap();
      }
      navigate('/dashboard');
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
  };

  const toggleForm = () => {
    setIsRegistering(!isRegistering);
    setError('');
  };

  return (
    <div className="flex min-h-screen w-full">
      {/* Left side - Dark section */}
      <div className="hidden lg:flex lg:w-1/2 bg-black text-white flex-col justify-between">
        <div className="p-12">
          <div className="flex items-center space-x-2">
            <img 
              src="/flowOrchestraIconBlk.png" 
              alt="Flow Orchestra Icon" 
              className="w-8 h-8 bg-white rounded-md"
            />
            <span className="text-xl font-semibold">Flow Orchestra</span>
          </div>
        </div>
        <div className="p-12">
          <p className="text-lg">"The whole is greater than the sum of its parts."</p>
          <p className="mt-2 font-semibold">Aristotle</p>
        </div>
      </div>

      {/* Right side - Login/Register form */}
      <div className="flex-1 flex flex-col justify-between px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <div className="flex-1 flex flex-col justify-center">
          <div className="w-full max-w-sm">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl font-bold text-inter">
                Sign in
              </h2>
              {/*<button onClick={toggleForm} className="text-sm text-gray-600 hover:text-gray-900">
                Create account
              </button>*/}
            </div>
            <p className="mt-2 text-sm text-gray-600">
              Enter your credentials below to access your account
            </p>
            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
              <div className="space-y-4">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email address"
                  required
                  className="w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  required
                  className="w-full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit" className="w-full bg-black text-white hover:bg-gray-800">
                  Sign In
                </Button>
              </div>
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
            </form>
            <p className="mt-6 text-center text-sm text-gray-500">
              By signing in, you agree to our{' '}
              <a href="#" className="font-medium text-gray-900 hover:underline">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="#" className="font-medium text-gray-900 hover:underline">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
        <div className="mt-8 w-full flex flex-col items-end">
          <p className="text-lg text-black">"AI and automation play the notes of innovation."</p>
          <p className="mt-2 font-semibold text-black">Flow Orchestra</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;