import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFlows,
  deleteFlow,
  setCurrentPage,
  setItemsPerPage,
  createFlow,
  cloneFlow,
} from "../slices/flowsSlice";
import { startExecution } from "../slices/executionsSlice";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Pagination } from "../components/ui/pagination";
import { Input } from "../components/ui/input";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "../components/ui/collapsible";
import { ChevronDown, ChevronUp, Edit, Trash2, Play, Copy, Plus, Clock, Tag, Eye, EyeOff, BarChart, Maximize2, Minimize2 } from "lucide-react";
import { debounce } from "lodash";
import { useToast } from "../components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";

const FlowListItem = ({ flow, isSelected, onSelect, onDelete }) => (
  <Card
  className={`cursor-pointer transition-colors ${
    flow.privacy === 'global' 
      ? isSelected
        ? "bg-amber-700 text-white hover:bg-amber-800"
        : "bg-amber-100 hover:bg-amber-200"
      : isSelected 
        ? "bg-gray-900 text-white" 
        : "hover:bg-gray-100"
  }`}
  onClick={() => onSelect(flow)}
>
    <CardHeader className="p-4">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold">{flow.name}</h3>
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
              <Badge variant="outline" className={`${
      isSelected ? "text-white" : ""
    }`}>
                  <Clock className="w-3 h-3 mr-1" />
                  {flow.averageExecutionTime.toFixed(1)}s
                </Badge>
              </TooltipTrigger>
              <TooltipContent>Average execution time</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge variant="outline" className={`${
      isSelected ? "text-white" : ""
    }`}>
                  <BarChart className="w-3 h-3 mr-1" />
                  {flow.executionCount}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>Execution count</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => e.stopPropagation()}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the flow.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={(e) => {
                  e.stopPropagation();
                  onDelete(flow._id);
                }}>
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <p className={`text-xs ${isSelected ? "text-gray-100" : "text-muted-foreground"}`}>{flow.description}</p>
      <div className="flex flex-wrap gap-1 mt-2">
        {flow.tags.map((tag, index) => (
          <Badge key={index} variant="secondary" className="text-xs">
            {tag}
          </Badge>
        ))}
      </div>
    </CardHeader>
  </Card>
);

const FlowList = ({ flows, onSelectFlow, selectedFlowId, onDeleteFlow }) => (
  <ScrollArea className="h-full">
    <div className="space-y-2 p-4">
      {flows.map((flow) => (
        <FlowListItem
          key={flow._id}
          flow={flow}
          isSelected={selectedFlowId === flow._id}
          onSelect={onSelectFlow}
          onDelete={onDeleteFlow}
        />
      ))}
    </div>
  </ScrollArea>
);

const StepDetails = ({ step, index }) => (
  <Collapsible className="border rounded-md p-3 px-6 mb-2 hover:bg-gray-200 group data-[state=open]:bg-white data-[state=open]:hover:bg-white">
    <CollapsibleTrigger className="flex justify-between items-center w-full">
      <span className="font-semibold">{index + 1}. {step.name}</span>
      <ChevronDown className="h-4 w-4" />
    </CollapsibleTrigger>
    <CollapsibleContent className="space-y-2">
      <p><strong>Type:</strong> {step.type}</p>
      {/*<p><strong>Category:</strong> {step.category}</p>*/}
      <p className="text-sm text-gray-700 mb-2">{step.description}</p>
      <p><strong>Instructions:</strong> {step.prompt || step.description}</p>
      <p><strong>Output Key:</strong> {step.outputKey}</p>
      {step.assetType && <p><strong>Asset Type:</strong> {step.assetType}</p>}
      {step.assetData && <p><strong>Asset Data:</strong> {step.assetData}</p>}
      {step.quantity && <p><strong>Quantity:</strong> {step.quantity}</p>}
    </CollapsibleContent>
  </Collapsible>
);

const FlowDetails = ({
  flow,
  onEdit,
  onDelete,
  onExecute,
  onClone,
}) => (
  <ScrollArea className="h-full">
    <div className="p-6 pt-0 space-y-2">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">{flow.name}</h2>
        <div className="flex space-x-2">
          <Button variant="outline" size="sm" onClick={() => onEdit(flow._id)}>
            <Edit className="w-4 h-4 mr-1" /> Edit
          </Button>
          <Button variant="outline" size="sm" onClick={() => onExecute(flow._id)}>
            <Play className="w-4 h-4 mr-1" /> Execute
          </Button>
          <Button variant="outline" size="sm" onClick={() => onClone(flow._id)}>
            <Copy className="w-4 h-4 mr-1" /> Clone
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="destructive" size="sm">
                <Trash2 className="w-4 h-4 mr-1" /> Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the flow.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={() => onDelete(flow._id)}>
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <p className="text-muted-foreground">{flow.description}</p>
      <div className="flex flex-wrap gap-2">
        {flow.tags.map((tag, index) => (
          <Badge key={index} variant="secondary">
            <Tag className="w-3 h-3 mr-1" />
            {tag}
          </Badge>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-4 border rounded p-4 bg-white">
        <div>
          <h3 className="text-lg font-semibold mb-2">Details</h3>
          <p className="capitalize"><strong>Privacy:</strong> {flow.privacy}</p>
          <p><strong>Active:</strong> {flow.isActive ? "Yes" : "No"}</p>
          <p><strong>Execution Count:</strong> {flow.executionCount}</p>
          <p><strong>Avg. Execution Time:</strong> {flow.averageExecutionTime.toFixed(1)}s</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Dates</h3>
          <p><strong>Created:</strong> {new Date(flow.createdAt).toLocaleString()}</p>
          <p><strong>Updated:</strong> {new Date(flow.updatedAt).toLocaleString()}</p>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Steps</h3>
        {flow.steps.map((step, index) => (
          <StepDetails key={step._id} step={step} index={index} />
        ))}
      </div>
    </div>
  </ScrollArea>
);

const FlowManagement = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [jsonError, setJsonError] = useState(null);
  const [isJsonExpanded, setIsJsonExpanded] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { flows, status, error, totalItems, currentPage, itemsPerPage } =
    useSelector((state) => state.flows);
  const { user } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedFlows, setExpandedFlows] = useState({});
  const { teams } = useSelector((state) => state.teams);
  const { selectedTeam } = useSelector((state) => state.teams);
  const [isCardMinimized, setIsCardMinimized] = useState(true);

  const toggleCardMinimized = () => {
    setIsCardMinimized(!isCardMinimized);
  };

  const handleJsonSubmit = () => {
    setJsonError(null);
    try {
      const parsedJson = JSON.parse(jsonInput);
      dispatch(createFlow({ ...parsedJson, team: selectedTeam || null }))
        .unwrap()
        .then(() => {
          setJsonInput("");
          setIsJsonExpanded(false);
          toast({
            title: "Success",
            description: "Flow created successfully from JSON",
            variant: "default",
          });
          debouncedFetchFlows(
            currentPage,
            itemsPerPage,
            searchTerm,
            selectedTeam
          );
        })
        .catch((error) => {
          setJsonError(error.message);
          toast({
            title: "Error",
            description: "Failed to create flow from JSON",
            variant: "destructive",
          });
        });
    } catch (error) {
      setJsonError("Invalid JSON format");
    }
  };

  const toggleJsonSection = () => {
    setIsJsonExpanded(!isJsonExpanded);
  };

  const debouncedFetchFlows = debounce((page, limit, search, teamId) => {
    dispatch(fetchFlows({ page, limit, search, teamId }));
  }, 300);

  useEffect(() => {
    debouncedFetchFlows(currentPage, itemsPerPage, searchTerm, selectedTeam);
  }, [currentPage, itemsPerPage, searchTerm, selectedTeam, dispatch]);

  const handleEdit = (id) => {
    navigate(`/edit-flow/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteFlow(id))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Flow deleted successfully",
          variant: "default",
        });
        setSelectedFlow(null);
        debouncedFetchFlows(
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedTeam
        );
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to delete flow: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleClone = (id) => {
    dispatch(cloneFlow(id))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Flow cloned successfully",
          variant: "default",
        });
        debouncedFetchFlows(
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedTeam
        );
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to clone flow: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    dispatch(setCurrentPage(1));
  };

  const handleItemsPerPageChange = (value) => {
    dispatch(setItemsPerPage(Number(value)));
    dispatch(setCurrentPage(1));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const toggleFlowExpansion = (id) => {
    setExpandedFlows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleExecute = (id) => {
    dispatch(startExecution(id))
      .unwrap()
      .then((result) => {
        navigate(`/execution/${result.executionId}`, { state: { isNewExecution: true } });
      })
      .catch((error) => {
        console.error("Failed to start execution:", error);
        toast({
          title: "Error",
          description: "Failed to start execution: " + error.message,
          variant: "destructive",
        });
      });
  };

  if (flows.length === 0) {
    return <div>You have not created any flows yet</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex h-[calc(100vh-12rem)]">
      <div className="w-1/3 border-r">
        <div className="p-4 py-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Flows</h1>
            <Button onClick={() => navigate("/create-flow")}>
              <Plus className="w-4 h-4 mr-1" /> New Flow
            </Button>
          </div>
          <Input
            type="text"
            placeholder="Search flows..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <FlowList
          flows={flows}
          onSelectFlow={setSelectedFlow}
          selectedFlowId={selectedFlow?._id}
          onDeleteFlow={handleDelete}
        />
        <div className="p-4 border-t">
          <div className="flex justify-between items-center">
            <Select
              value={itemsPerPage.toString()}
              onValueChange={handleItemsPerPageChange}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Items per page" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="10">10 per page</SelectItem>
                <SelectItem value="20">20 per page</SelectItem>
                <SelectItem value="50">50 per page</SelectItem>
              </SelectContent>
            </Select>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="w-2/3 h-[calc(100vh-2rem)]">
        {selectedFlow ? (
          <FlowDetails
            flow={selectedFlow}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onExecute={handleExecute}
            onClone={handleClone}
          />
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">Select a flow to view details</p>
          </div>
        )}
      </div>
      {isCardMinimized ? (
  <Button
    className="fixed bottom-4 right-4 p-2"
    onClick={toggleCardMinimized}
    variant="outline"
  >
    <Plus className="h-6 w-6" />
  </Button>
) : (
  <Card className="fixed bottom-4 right-4 w-96">
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <h3 className="font-bold text-lg">Quick Create Flow</h3>
      <Button
        variant="ghost"
        size="icon"
        onClick={toggleCardMinimized}
      >
        <Minimize2 className="h-4 w-4" />
      </Button>
    </CardHeader>
    <CardContent>
      <Collapsible open={isJsonExpanded} onOpenChange={toggleJsonSection}>
        <CollapsibleTrigger asChild>
          <Button
            variant="outline"
            className="w-full flex justify-between items-center"
          >
            Create Flow from JSON
            {isJsonExpanded ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent className="mt-4">
          <Textarea
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
            placeholder="Paste your flow JSON here"
            className="w-full h-40 mb-2"
          />
          <Button onClick={handleJsonSubmit} className="w-full">
            Create Flow
          </Button>
          {jsonError && (
            <Alert variant="destructive" className="mt-2">
              <AlertTitle>JSON Error</AlertTitle>
              <AlertDescription>{jsonError}</AlertDescription>
            </Alert>
          )}
          <a 
            href="/JSON_Flow_Example.txt" 
            download 
            className="text-blue-600 hover:text-blue-800 underline ml-2 text-sm mt-2"
          >
            Download JSON example
          </a>
        </CollapsibleContent>
      </Collapsible>
    </CardContent>
  </Card>
)}
    </div>
  );
};

export default FlowManagement;