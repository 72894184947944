import React, { useState, useEffect } from 'react';
import { Card } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Clock, BarChart2, Plus, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';

const UserChatbotList = ({ chatbots, activeChatbot, onChatbotSelect, onNewChatbot, onDeleteChatbot }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChatbots, setFilteredChatbots] = useState(chatbots);
    const [chatbotToDelete, setChatbotToDelete] = useState(null);
  
    useEffect(() => {
      const filtered = chatbots.filter(chatbot => 
        chatbot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        chatbot.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredChatbots(filtered);
    }, [chatbots, searchTerm]);
  
    const handleDeleteClick = (e, chatbot) => {
      e.stopPropagation();
      setChatbotToDelete(chatbot);
    };
  
    const handleConfirmDelete = () => {
      if (chatbotToDelete && onDeleteChatbot) {
        onDeleteChatbot(chatbotToDelete._id);
      }
      setChatbotToDelete(null);
    };
  
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Custom Assistants</h2>
          <Button onClick={onNewChatbot} className="bg-gray-900 hover:bg-gray-700 text-white">
            <Plus className="w-4 h-4 mr-1" /> New Assistant
          </Button>
        </div>
        <div className='text-sm'>
            Design personalized AI assistants tailored to your needs by setting prompts, instructions, and more. 
            Once created, your assistants are available to chat with directly in the Chat section of the app, making it easy to get the help or interaction you need anytime.
        </div>
        <Input
          type="text"
          placeholder="Search custom assistants..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full"
        />
        <div className="space-y-2">
          {filteredChatbots.map((chatbot) => (
            <Card
              key={chatbot._id}
              className={`p-4 cursor-pointer ${
                activeChatbot && activeChatbot._id === chatbot._id
                  ? 'bg-gray-900 border-gray-800 text-white'
                  : 'hover:bg-gray-50'
              }`}
              onClick={() => onChatbotSelect(chatbot)}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold text-lg">{chatbot.name}</h3>
                  <p className={`text-sm text-gray-600 mt-1 ${
                activeChatbot && activeChatbot._id === chatbot._id
                  ? 'text-gray-200'
                  : ''
              }`}>{chatbot.description}</p>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={(e) => handleDeleteClick(e, chatbot)}
                    >
                      <Trash2 className="h-4 w-4 text-gray-400" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the custom assistant
                        and remove its data from our servers.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={handleConfirmDelete}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
              <div className={`flex items-center space-x-4 mt-2 text-sm ${
                activeChatbot && activeChatbot._id === chatbot._id
                  ? 'text-gray-300'
                  : 'text-gray-500'
              }`}>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {chatbot.date_added ? new Date(chatbot.date_added).toLocaleDateString() : 'N/A'}
                </div>
                <div className="flex items-center">
                  <BarChart2 className="h-4 w-4 mr-1" />
                  {chatbot.usageCount || 0}
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    );
  };
  
  export default UserChatbotList;