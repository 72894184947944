import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from "../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Input } from "../../components/ui/input";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faComment, faPencilAlt, faCheck, faSave } from '@fortawesome/free-solid-svg-icons';
import { updateChatTitle } from '../../slices/chatSlice';
import { updateTitle } from '../../services/api';
import { toast } from "../../components/ui/use-toast";

const ChatHeader = ({
  currentChat,
  handleNewChat,
  selectedAssistant,
  handleSwitchAssistant,
  chatTitle,
  setChatTitle,
  assistants
}) => {
  const dispatch = useDispatch();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const getSelectedAssistant = () => assistants.find(a => a._id === selectedAssistant);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedTitle(currentChat.title);
  };

  const handleSaveTitle = async () => {
    if (editedTitle.trim() !== '') {
      try {
        await updateTitle(currentChat._id, editedTitle);
        dispatch(updateChatTitle({ chatId: currentChat._id, title: editedTitle }));
        setIsEditingTitle(false);
        toast({
          title: "Title updated",
          description: "The chat title has been successfully updated.",
          duration: 3000,
        });
      } catch (error) {
        console.error('Failed to update title:', error);
        toast({
          title: "Error",
          description: "Failed to update the chat title. Please try again.",
          variant: "destructive",
          duration: 3000,
        });
      }
    }
  };

  return (
    <div className="p-4 pt-0 border-b w-full">
      <div className="flex justify-between items-center space-x-4">
        <div className="w-1/4">
          <Button onClick={handleNewChat} variant="outline" className="w-fit">
            New Chat
          </Button>
        </div>
        <div className="w-1/3 flex items-center">
        <Avatar className="w-8 h-8 mr-2 bg-black flex items-center justify-center">
  {selectedAssistant ? (
    getSelectedAssistant()?.type === 'OpenAIAssistant' ? (
      <img src={getSelectedAssistant()?.avatarUrl} alt={getSelectedAssistant()?.name} className="object-cover" />
    ) : (
      <AvatarFallback className="bg-gray-900">
        <FontAwesomeIcon icon={faRobot} className="text-white" />
      </AvatarFallback>
    )
  ) : (
    <AvatarFallback className="bg-gray-900">
      <FontAwesomeIcon icon={faRobot} className="text-white" />
    </AvatarFallback>
  )}
</Avatar>
          <Select
            value={selectedAssistant}
            onValueChange={handleSwitchAssistant}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select an assistant" />
            </SelectTrigger>
            <SelectContent>
  {assistants.map((assistant) => (
    <SelectItem key={assistant._id} value={assistant._id}>
      <div className='flex items-center'>
        <Avatar className="w-6 h-6 mr-4">
          {assistant.type === 'OpenAIAssistant' ? (
            <img src={assistant.avatarUrl} alt={assistant.name} className="object-cover" />
          ) : (
            <AvatarFallback className="bg-gray-900">
              <FontAwesomeIcon icon={faRobot} className="text-white" />
            </AvatarFallback>
          )}
        </Avatar>
        {assistant.name}
      </div>
    </SelectItem>
  ))}
</SelectContent>
          </Select>
        </div>
        {currentChat ? (
          <div className="w-1/3 text-right flex items-center justify-end">
            {isEditingTitle ? (
              <>
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className="w-full mr-2"
                />
                <Button onClick={handleSaveTitle} variant="ghost" size="icon" className="h-8 w-8">
                  <FontAwesomeIcon icon={faSave} className="text-green-500" />
                </Button>
              </>
            ) : (
              <>
                <h2 className="text-lg font-semibold truncate mr-2">{currentChat.title}</h2>
                <Button onClick={handleEditTitle} variant="ghost" size="icon" className="h-8 w-8">
                  <FontAwesomeIcon icon={faPencilAlt} className="text-gray-500" />
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className="w-1/3 flex items-center">
            <Avatar className="w-8 h-8 mr-2 flex items-center justify-center">
              <AvatarFallback className="bg-gray-900">
                <FontAwesomeIcon icon={faComment} className="text-white" />
              </AvatarFallback>
            </Avatar>
            <Input
              type="text"
              placeholder="Enter chat title"
              value={chatTitle}
              onChange={(e) => setChatTitle(e.target.value)}
              className="w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatHeader;