// ChatComponent.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChatById, sendMessage, createChat, switchAssistant, uploadFile } from '../services/api';
import { setCurrentChat, addMessage, setLoading, setError } from '../slices/chatSlice';
import { fetchUserChatbots } from '../slices/userChatbotSlice';
import { fetchAssistants } from '../slices/flowsSlice';
import { SocketContext } from '../App';
import ChatHeader from './ChatComponents/ChatHeader';
import MessageList from './ChatComponents/MessageList';
import ChatInput from './ChatComponents/ChatInput';
import NewChatInstructions from './ChatComponents/NewChatInstructions';
import ChatHistorySidebar from './ChatComponents/ChatHistorySidebar';
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../components/ui/alert-dialog';

const ChatComponent = () => {
  const { chatId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const { currentChat, isLoading, error } = useSelector((state) => state.chat);
  const { assistants } = useSelector((state) => state.flows);
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [streamedResponse, setStreamedResponse] = useState('');
  const [selectedAssistant, setSelectedAssistant] = useState('');
  const [chatTitle, setChatTitle] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const userChatbots = useSelector((state) => state.userChatbots.chatbots);
const [combinedAssistants, setCombinedAssistants] = useState([]);
const [files, setFiles] = useState([]);
const [sentFiles, setSentFiles] = useState([]);

useEffect(() => {
  const fetchAllAssistants = async () => {
    await dispatch(fetchAssistants());
    await dispatch(fetchUserChatbots());
  };
  fetchAllAssistants();
}, [dispatch]);

useEffect(() => {
  const chattableUserChatbots = userChatbots
    .filter(chatbot => chatbot.isChattable)
    .map(chatbot => ({
      ...chatbot,
      type: 'UserChatbot'
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const groupedOpenAIAssistants = assistants
  .filter(assistant => assistant.isChattable)
    .map(assistant => ({
      ...assistant,
      type: 'OpenAIAssistant'
    }))
    .reduce((acc, assistant) => {
      const group = assistant.assistantType || 'other';
      if (!acc[group]) acc[group] = [];
      acc[group].push(assistant);
      return acc;
    }, {});

  const sortedGroups = ['personal', 'general', 'specialist', 'other'];
  
  const sortedOpenAIAssistants = sortedGroups.flatMap(group => 
    (groupedOpenAIAssistants[group] || []).sort((a, b) => a.name.localeCompare(b.name))
  );

  setCombinedAssistants([...chattableUserChatbots, ...sortedOpenAIAssistants]);
}, [assistants, userChatbots]);

  useEffect(() => {
    if (currentChat && currentChat.assistant?._id) {
      setSelectedAssistant(currentChat.assistant._id);
    } else if (currentChat && currentChat.assistant) {
      setSelectedAssistant(currentChat.assistant);
    }

    if (currentChat && currentChat.title) {
      setChatTitle(currentChat.title);
    }
  }, [currentChat]);

  useEffect(() => {
    if (currentChat && currentChat._id && !chatId) {
      navigate(`/chat/${currentChat._id}`);
    }
  }, [currentChat, chatId, navigate]);

  const handleChatStream = useCallback(({ chunkContent, userId, chatId: streamChatId }) => {
    if (streamChatId === chatId) {
      if (chunkContent === '--complete--') {
        setIsTyping(false);
        if (streamedResponse) {
          dispatch(addMessage({ role: 'assistant', content: streamedResponse }));
          setStreamedResponse('');
        }
      } else {
        setIsTyping(true);
        setIsWaiting(false);
        setStreamedResponse(prev => prev + chunkContent);
      }
    }
  }, [chatId, dispatch, streamedResponse]);

  useEffect(() => {
    if (socket) {
      socket.on('new message', (message) => {
        dispatch(addMessage(message));
      });

      socket.on('chatStream', handleChatStream);

      return () => {
        socket.off('new message');
        socket.off('chatStream');
      };
    }
  }, [socket, dispatch, handleChatStream]);

  useEffect(() => {
    if (chatId && socket && socket.connected) {
      socket.emit('join chat', chatId);
      return () => {
        socket.emit('leave chat', chatId);
      };
    }
  }, [chatId, socket]);

  useEffect(() => {
    if (chatId) {
      dispatch(setLoading(true));
      fetchChatById(chatId)
        .then((data) => {
          dispatch(setCurrentChat(data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setError(err.message));
          dispatch(setLoading(false));
        });
    }
  }, [chatId, dispatch]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if ((!selectedAssistant || (!chatTitle && !currentChat?.title) || (!message.trim() && files.length === 0))) {
      let missingItems = [];
      if (!selectedAssistant) missingItems.push('an assistant');
      if (!chatTitle && !currentChat?.title) missingItems.push('a chat title');
      if (!message.trim() && files.length === 0) missingItems.push('a message or file');
      
      const missingItemsText = missingItems.join(' and ');
      setAlertMessage(`Please provide ${missingItemsText} before sending a message.`);
      setShowAlert(true);
      return;
    }

    let chatToUse = currentChat;

    if (!currentChat) {
      try {
        const newChat = await createChat(selectedAssistant, chatTitle);
        chatToUse = newChat.data;
        dispatch(setCurrentChat(chatToUse));
        navigate(`/chat/${chatToUse._id}`);
      } catch (err) {
        dispatch(setError('Failed to create chat'));
        return;
      }
    }

    setIsTyping(true);
    setIsWaiting(true);

    try {
      let fileIds = [];
      if (files.length > 0) {
        const uploadPromises = files.map(file => uploadFile(chatToUse._id, file));
        const uploadResults = await Promise.all(uploadPromises);
        console.log('Upload results:', uploadResults);
        fileIds = uploadResults.map(result => result.data.fileId);
      }

      const newMessage = { role: 'user', content: message, files: files.map(f => f.name) };
      dispatch(addMessage(newMessage));
      setMessage('');

      await sendMessage(chatToUse._id, message, fileIds);
      setSentFiles(prev => [...prev, ...files]);
      setFiles([]); // Clear files after sending
    } catch (err) {
      dispatch(setError('Failed to send message'));
    }
  };

  /*const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!selectedAssistant || (!chatTitle && !currentChat?.title) || !message.trim()) {
      let missingItems = [];
      if (!selectedAssistant) missingItems.push('an assistant');
      if (!chatTitle && !currentChat?.title) missingItems.push('a chat title');
      if (!message.trim()) missingItems.push('a message');
      
      const missingItemsText = missingItems.join(' and ');
      setAlertMessage(`Please provide ${missingItemsText} before sending a message.`);
      setShowAlert(true);
      return;
    }
    if (message.trim()) {
      const newMessage = { role: 'user', content: message };
      
      let chatToUse = currentChat;
  
      if (!currentChat) {
        try {
          const newChat = await createChat(selectedAssistant, chatTitle);
          chatToUse = newChat.data;
          dispatch(setCurrentChat(chatToUse));
          navigate(`/chat/${chatToUse._id}`);
        } catch (err) {
          dispatch(setError('Failed to create chat'));
          return;
        }
      }
      
      dispatch(addMessage(newMessage));
      setMessage('');
      setIsTyping(true);
      setIsWaiting(true);
  
      try {
        await sendMessage(chatToUse._id, message);
      } catch (err) {
        dispatch(setError('Failed to send message'));
      }
    }
  };*/

  const handleSwitchAssistant = async (assistantId) => {
    setSelectedAssistant(assistantId);
    if (currentChat && assistantId !== currentChat.assistant) {
      try {
        const selectedAssistant = combinedAssistants.find(a => a._id === assistantId);
        const updatedChat = await switchAssistant(currentChat._id, assistantId, selectedAssistant.type);
        dispatch(setCurrentChat(updatedChat.data.chat));
      } catch (err) {
        dispatch(setError('Failed to switch assistant'));
        setSelectedAssistant(currentChat.assistant);
      }
    }
  };

  const handleNewChat = () => {
    dispatch(setCurrentChat(null));
    setSelectedAssistant('');
    setChatTitle('');
    navigate('/chat');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flex h-[calc(100vh-2rem)]">
      <ChatHistorySidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-grow flex flex-col">
        <ChatHeader
          currentChat={currentChat}
          handleNewChat={handleNewChat}
          selectedAssistant={selectedAssistant}
          handleSwitchAssistant={handleSwitchAssistant}
          chatTitle={chatTitle}
          setChatTitle={setChatTitle}
          assistants={combinedAssistants}
          toggleSidebar={toggleSidebar}
        />
        <div className="flex-grow overflow-y-auto p-4">
          {currentChat && currentChat.messages ? (
            <MessageList
              messages={currentChat.messages}
              isTyping={isTyping}
              isWaiting={isWaiting}
              streamedResponse={streamedResponse}
            />
          ) : (
            <NewChatInstructions />
          )}
        </div>
        <ChatInput
      message={message}
      setMessage={setMessage}
      handleSendMessage={handleSendMessage}
      currentChat={currentChat}
      selectedAssistant={selectedAssistant}
      chatTitle={chatTitle}
      files={files}
      setFiles={setFiles}
      sentFiles={sentFiles}
    />
      </div>
      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Missing Information</AlertDialogTitle>
            <AlertDialogDescription>{alertMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowAlert(false)}>OK</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ChatComponent;