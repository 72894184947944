import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { FormHeader, Label } from "./SharedComponents";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

const TutorLMSCourseForm = ({
  courseTutorLMSSiteUrl,
  setCourseTutorLMSSiteUrl,
  courseTitle,
  setCourseTitle,
  courseContent,
  setCourseContent,
  courseExcerpt,
  setCourseExcerpt,
  courseLevel,
  setCourseLevel,
  courseThumbnail,
    setCourseThumbnail,
  courseStatus,
  setCourseStatus,
  handleCourseTutorLMSPost,
  config,
  setIsExpanded,
  savedCourse,
  courseCategories,
    setCourseCategories,
    courseTags,
    setCourseTags,
    additionalContent,
    setAdditionalContent,
    courseVideo,
    setCourseVideo,
}) => {
  console.log("savedCourse", savedCourse);

  const [openTopics, setOpenTopics] = useState({});

  const toggleTopic = (topicId) => {
    setOpenTopics((prev) => ({
      ...prev,
      [topicId]: !prev[topicId],
    }));
  };

  function pluralize(count, word) {
    if (count === 1) {
      return `${count} ${word}`;
    }
    if (word.toLowerCase() === "quiz") {
      return `${count} ${word}zes`;
    }
    return `${count} ${word}s`;
  }

  const topics = savedCourse.topics?.map((topic) => ({
    ...topic,
  }));

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-4">
      <FormHeader
        title="TutorLMS Course Settings"
        onClose={() => setIsExpanded(false)}
      />
      <div className="space-y-6">
      <Button
          onClick={handleCourseTutorLMSPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faGraduationCap} className='mr-2' />Submit Course to TutorLMS
        </Button>
        <div>
          <Label tooltip="Select the TutorLMS site where you want to post this course">
            TutorLMS Site
          </Label>
          <Select
            value={courseTutorLMSSiteUrl}
            onValueChange={setCourseTutorLMSSiteUrl}
          >
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select TutorLMS site" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="https://aheadofthegap.com">
                Ahead of the Gap
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label tooltip="Enter the title of your course">Course Title</Label>
          <Input
            type="text"
            value={courseTitle}
            placeholder="Create an informative, engaging title (e.g., 'Master Python in 30 Days')"
            onChange={(e) => setCourseTitle(e.target.value)}
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Enter the title of your course">Course Featured Image URL</Label>
          <Input
            type="text"
            value={courseThumbnail}
            onChange={(e) => setCourseThumbnail(e.target.value)}
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Provide a detailed description of the course">
            Course Description
          </Label>
          <Textarea
            value={courseContent}
            placeholder="Detail course content, structure, outcomes. Why take this course?"
            onChange={(e) => setCourseContent(e.target.value)}
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Enter a brief summary of the course">
            Course Excerpt
          </Label>
          <Textarea
            value={courseExcerpt}
            placeholder="Write a brief, enticing summary to appear in course listings (100-150 characters)"
            onChange={(e) => setCourseExcerpt(e.target.value)}
            className="bg-white"
          />
        </div>

        <div>
          <Label tooltip="Select the course level">Course Level</Label>
          <Select value={courseLevel} onValueChange={setCourseLevel}>
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select course level" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="beginner">Beginner</SelectItem>
              <SelectItem value="intermediate">Intermediate</SelectItem>
              <SelectItem value="advanced">Advanced</SelectItem>
            </SelectContent>
          </Select>
        </div>

        {/* Course Topics, Lessons, and Quizzes */}
        <div>
          <Label tooltip="Course structure">
            <span className="mr-5">Course Structure:</span>{" "}
            {`${pluralize(savedCourse.topics?.length, "Topic")} - ${pluralize(
              savedCourse.lessons?.length,
              "Lesson"
            )} - ${pluralize(savedCourse.quizzes?.length, "Quiz")}`}
          </Label>
          {topics?.map((topic, topicIndex) => (
            <Collapsible
              key={topicIndex}
              open={openTopics[topic.topic_id]}
              onOpenChange={() => toggleTopic(topic.topic_id)}
            >
              <CollapsibleTrigger className="w-full text-left py-2 px-4 bg-gray-300 hover:bg-gray-200 rounded-md mb-2">
                <FontAwesomeIcon
                  icon={
                    openTopics[topic.topic_id]
                      ? faChevronUp
                      : faChevronDown
                  }
                  className="mr-2"
                />
                {`${topic.topic_title} - ${pluralize(
  (savedCourse.lessons || []).filter(
    (lesson) => String(lesson.topic_id) === String(topic.topic_id)
  ).length,
  "Lesson"
)}, ${pluralize(
  (savedCourse.quizzes || []).filter(
    (quiz) => String(quiz.topic_id) === String(topic.topic_id)
  ).length,
  "Quiz"
)}`}
              </CollapsibleTrigger>
              <CollapsibleContent className="ml-4 mb-4">
              <h4 className="font-semibold mb-2">
  Lessons: (
  {
    (savedCourse.lessons || []).filter(
      (lesson) => String(lesson.topic_id) === String(topic.topic_id)
    ).length
  }
  )
</h4>
<ul className="list-disc list-inside mb-2">
  {(savedCourse.lessons || [])
    .filter((lesson) => String(lesson.topic_id) === String(topic.topic_id))
    .map((lesson, lessonIndex) => (
      <li key={lessonIndex}>{lesson.lesson_title}</li>
    ))}
</ul>
<h4 className="font-semibold mb-2">
  Quizzes: (
  {
    (savedCourse.quizzes || []).filter(
      (quiz) => String(quiz.topic_id) === String(topic.topic_id)
    ).length
  }
  )
</h4>
<ul className="list-disc list-inside">
  {(savedCourse.quizzes || [])
    .filter((quiz) => String(quiz.topic_id) === String(topic.topic_id))
    .map((quiz, quizIndex) => (
      <li key={quizIndex}>{quiz.quiz_title}</li>
    ))}
</ul>
              </CollapsibleContent>
            </Collapsible>
          ))}
        </div>

        <div>
          <Label tooltip="Set the course status">Status</Label>
          <Select value={courseStatus} onValueChange={setCourseStatus}>
            <SelectTrigger className="w-full bg-white">
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="draft">Draft</SelectItem>
              <SelectItem value="publish">Publish</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <Button
          onClick={handleCourseTutorLMSPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faGraduationCap} className='mr-2' />Submit Course to TutorLMS
        </Button>
        
      </div>
    </div>
  );
};

export default TutorLMSCourseForm;
