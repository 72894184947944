import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../components/ui/dialog";
import { PlusCircle, Save, ChevronUp, ChevronDown, X, Copy, Trash2 } from 'lucide-react';
import { fetchAssistants } from '../slices/flowsSlice';
import StepCard from './FlowFormComponents/StepCard';
import { 
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel
} from "../components/ui/alert-dialog";

const FlowForm = ({ 
  initialFlow, 
  onSubmit, 
  submitButtonText, 
  isSubmitting,
  submittingText,
  isEditMode = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assistants = useSelector(state => state.flows.assistants);
  const assistantsStatus = useSelector(state => state.flows.assistantsStatus);
  const assistantsError = useSelector(state => state.flows.assistantsError);

  const [flowData, setFlowData] = useState({
    name: '',
    description: '',
    steps: []
  });
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);

  useEffect(() => {
    if (initialFlow) {
      setFlowData({
        name: initialFlow.name,
        description: initialFlow.description,
        steps: initialFlow.steps.map(step => ({
          ...step,
          category: step.category || 'generate',
          type: step.type || (step.category === 'generate' ? 'aiRequest' : 'saveAsset')
        }))
      });
      setCurrentStepIndex(0);
    }
    if (assistantsStatus === 'idle') {
      dispatch(fetchAssistants());
    }
  }, [initialFlow, assistantsStatus, dispatch]);

  const handleAddStep = useCallback(() => {
    setFlowData(prevData => {
      const insertIndex = currentStepIndex !== null ? currentStepIndex + 1 : prevData.steps.length;
      const newSteps = [...prevData.steps];
      
      newSteps.splice(insertIndex, 0, {
        category: 'generate',
        type: 'aiRequest',
        name: `Step ${prevData.steps.length + 1}`,
        description: '',
        outputKey: `step${prevData.steps.length + 1}_output`
      });
  
      return { ...prevData, steps: newSteps };
    });
    setCurrentStepIndex(currentStepIndex !== null ? currentStepIndex + 1 : 0);
  }, [currentStepIndex]);

  const handleStepChange = useCallback((index, fieldOrUpdates, value) => {
    setFlowData((prevData) => {
      const newSteps = [...prevData.steps];
      const step = { ...newSteps[index] };
  
      if (typeof fieldOrUpdates === 'object') {
        // Handle multiple updates
        newSteps[index] = { ...step, ...fieldOrUpdates };
      } else {
        const fieldPath = fieldOrUpdates.split('.');
  
        if (fieldPath.length === 1) {
          // Single-level field
          step[fieldOrUpdates] = value;
        } else {
          // Nested field
          let current = step;
          for (let i = 0; i < fieldPath.length - 1; i++) {
            const key = fieldPath[i];
            if (!current[key] || typeof current[key] !== 'object') {
              current[key] = {};
            } else {
              current[key] = { ...current[key] };
            }
            current = current[key];
          }
          current[fieldPath[fieldPath.length - 1]] = value;
        }
  
        newSteps[index] = step;
      }
  
      return { ...prevData, steps: newSteps };
    });
  }, []);


  const handleRemoveStep = useCallback((index) => {
    setFlowData(prevData => ({
      ...prevData,
      steps: prevData.steps.filter((_, i) => i !== index)
    }));
    setCurrentStepIndex(prevIndex => {
      if (prevIndex === index) {
        return index > 0 ? index - 1 : null;
      } else if (prevIndex > index) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  }, []);

  const moveStep = useCallback((fromIndex, toIndex) => {
    setFlowData(prevData => {
      const newSteps = [...prevData.steps];
      const [movedStep] = newSteps.splice(fromIndex, 1);
      newSteps.splice(toIndex, 0, movedStep);
      return { ...prevData, steps: newSteps };
    });
    setCurrentStepIndex(toIndex);
  }, []);

  const cloneStep = useCallback((index) => {
    setFlowData(prevData => {
      const newSteps = [...prevData.steps];
      const stepToClone = { ...newSteps[index] };
      
      // Create clone with modified name
      const clone = {
        ...stepToClone,
        name: `${stepToClone.name} (clone)`,
        outputKey: `${stepToClone.outputKey}_clone`
      };
      
      // Insert clone after the original step
      newSteps.splice(index + 1, 0, clone);
      return { ...prevData, steps: newSteps };
    });
    // Set focus to the new cloned step
    setCurrentStepIndex(index + 1);
  }, []);

  const generatePreviousStepPlaceholders = useCallback((currentIndex) => {
    return flowData.steps.slice(0, currentIndex).map((step, index) => 
      `{{${step.outputKey || `step${index + 1}_output`}}}`
    ).join(', ');
  }, [flowData.steps]);

  const validateForm = useCallback(() => {
    const errors = [];
    if (!flowData.name.trim()) errors.push("Flow name is required");
    if (!flowData.description.trim()) errors.push("Flow description is required");
    if (flowData.steps.length === 0) errors.push("At least one step is required");
    
    flowData.steps.forEach((step, index) => {
      if (!step.name.trim()) errors.push(`Step ${index + 1} name is required`);
      if (!step.outputKey.trim()) errors.push(`Step ${index + 1} output key is required`);
      // Add more step-specific validations here
    });

    return errors;
  }, [flowData]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setError(null);
    const errors = validateForm();
    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsValidationDialogOpen(true);
      return;
    }
    try {
      // Ensure all step data is included in the submission
      const submissionData = {
        ...flowData,
        steps: flowData.steps.map(step => ({
          ...step,
          category: step.category || 'generate',
          type: step.type || (step.category === 'generate' ? 'aiRequest' : 'saveAsset')
        }))
      };
      onSubmit(submissionData);
    } catch (error) {
      setError(error.message);
    }
  }, [flowData, onSubmit, validateForm]);

  return (
    <div className="flex h-[calc(100vh-5rem)] -mx-6 -mt-6 overflow-hidden">
      {/* Left Column */}
      <div className="w-1/3 border-r flex flex-col">
        <div className="p-4 pt-0 overflow-y-auto flex-grow">
          <h2 className="text-xl font-bold mb-4">Flow Overview</h2>
          <Card className="mb-4">
            <CardContent className="pt-4">
              <Label htmlFor="flowName" className="text-base font-semibold">Flow Name</Label>
              <Input
                id="flowName"
                value={flowData.name}
                onChange={(e) => setFlowData(prev => ({ ...prev, name: e.target.value }))}
                required
                className="mt-1"
                placeholder="Enter flow name"
              />
              
              <Label htmlFor="flowDescription" className="text-base font-semibold mt-4">Description</Label>
              <Textarea
                id="flowDescription"
                value={flowData.description}
                onChange={(e) => setFlowData(prev => ({ ...prev, description: e.target.value }))}
                className="mt-1"
                placeholder="Describe your flow"
              />
            </CardContent>
          </Card>
  
          <Card>
            <CardContent className="pt-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Steps</h3>
                <Button type="button" onClick={handleAddStep} variant="outline" size="sm">
                  <PlusCircle className="w-4 h-4 mr-2" /> Add Step
                </Button>
              </div>
              <ul className="space-y-2 max-h-[calc(100vh-30rem)] overflow-y-auto">
                {flowData.steps.map((step, index) => (
                  <li 
                  key={index}
                  className={`px-2 border rounded flex items-center justify-between cursor-pointer ${currentStepIndex === index ? 'bg-blue-100 font-bold' : 'bg-white'}`}
                  onClick={() => setCurrentStepIndex(index)}
                >
                  <span>{step.name}</span>
                  <div>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={(e) => { e.stopPropagation(); moveStep(index, index - 1); }}
                      disabled={index === 0}
                    >
                      <ChevronUp className="w-4 h-4" />
                    </Button>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={(e) => { e.stopPropagation(); moveStep(index, index + 1); }}
                      disabled={index === flowData.steps.length - 1}
                    >
                      <ChevronDown className="w-4 h-4" />
                    </Button>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={(e) => { e.stopPropagation(); cloneStep(index); }}
                    >
                      <Copy className="w-4 h-4" />
                    </Button>
                    <AlertDialog>
                      <AlertDialogTrigger asChild onClick={(e) => e.stopPropagation()}>
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          className="hover:bg-red-100"
                        >
                          <Trash2 className="w-4 h-4 text-red-600" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent onClick={(e) => e.stopPropagation()}>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Step</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete "{step.name}"? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveStep(index);
                            }}
                            className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
                          >
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
  
      {/* Right Column */}
      <div className="w-2/3 flex flex-col">
        <div className="p-4 pt-0 overflow-y-auto flex-grow">
        <div className='flex justify-between items-center mb-1'>
            <h2 className="text-xl font-bold mb-4">Step Details</h2>
            <div className="flex space-x-2">
              <Button 
                type="button"
                variant="outline"
                onClick={() => navigate('/manage-flows')}
              >
                <X className="w-4 h-4 mr-2" />
                Cancel
              </Button>
              <Button 
                type="submit" 
                disabled={isSubmitting || flowData.steps.length === 0}
                className="w-fit bg-gray-900 text-white hover:bg-gray-800"
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <>
                    <span className="loading loading-spinner"></span>
                    {submittingText}
                  </>
                ) : (
                  <>
                    <Save className="w-4 h-4 mr-2" />
                    {submitButtonText}
                  </>
                )}
              </Button>
            </div>
          </div>
          {currentStepIndex !== null && (
            <StepCard
              step={flowData.steps[currentStepIndex]}
              index={currentStepIndex}
              handleStepChange={handleStepChange}
              handleRemoveStep={handleRemoveStep}
              assistants={assistants}
              generatePreviousStepPlaceholders={generatePreviousStepPlaceholders}
              isFirstStep={currentStepIndex === 0}
            />
          )}
        </div>
  
        <div className="p-4 py-0 mt-auto">
          <Button 
            type="submit" 
            disabled={isSubmitting || flowData.steps.length === 0}
            className="w-full bg-gray-900 text-white hover:bg-gray-800"
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <>
                <span className="loading loading-spinner"></span>
                {submittingText}
              </>
            ) : (
              <>
                <Save className="w-4 h-4 mr-2" />
                {submitButtonText}
              </>
            )}
          </Button>
  
          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {assistantsStatus === 'failed' && (
            <Alert variant="destructive" className="mt-4">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>Failed to load AI Assistants: {assistantsError}</AlertDescription>
            </Alert>
          )}
        </div>
      </div>
  
      <Dialog open={isValidationDialogOpen} onOpenChange={setIsValidationDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Form Validation Errors</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <ul className="list-disc pl-5">
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FlowForm;