import React from 'react';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';

const UserChatbotDetails = ({ chatbot, onEditClick, onDeleteChatbot }) => {
  const handleDelete = () => {
    if (onDeleteChatbot) {
      onDeleteChatbot(chatbot._id);
    }
  };

  return (
    <Card className="py-4 px-10">
        <div className='flex flex-col items-center mb-8 rounded bg-gray-100 p-4'>
        <h2 className="text-2xl font-bold mb-4">{chatbot.name}</h2>
      <img src={chatbot.avatarUrl} alt={chatbot.name} className="w-24 h-24 rounded-full mb-4" />
      <p className="mb-2"><strong>Description:</strong> {chatbot.description}</p>
        </div>
      
      <div className="mb-2">
        
        <p className="whitespace-pre-wrap"><strong>Prompt:</strong> {chatbot.prompt}</p>
      </div>
      <p className="mb-2"><strong>Instructions:</strong> {chatbot.instructions}</p>
      <div className="mb-2">
        <strong>Suggested Prompts:</strong>
        <ul className="list-disc list-inside">
          {chatbot.suggested_prompts && chatbot.suggested_prompts.map((prompt, index) => (
            <li key={index}>{prompt}</li>
          ))}
        </ul>
      </div>
      <p className="mb-2"><strong>Date Added:</strong> {new Date(chatbot.date_added).toLocaleDateString()}</p>
      <div className="mt-8 space-x-2">
        <Button variant="outline" onClick={onEditClick}>Edit</Button>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant="destructive">Delete</Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete the chatbot
                and remove its data from our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete}>
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </Card>
  );
};

export default UserChatbotDetails;