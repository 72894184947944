// src/pages/ChatComponents/MessageList.js

import React, { useRef, useEffect, useState } from 'react';
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faChevronDown, faChevronUp, faCopy } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { formatDistanceToNow } from 'date-fns';

const MessageList = ({ messages, isTyping, isWaiting, streamedResponse }) => {
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const { toast } = useToast();

  useEffect(() => {
    setDisplayedMessages(messages);
  }, [messages]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        setShowScrollDown(scrollTop < scrollHeight - clientHeight - 100);
        setShowScrollUp(scrollTop > 100);
      };

      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (!showScrollDown) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, streamedResponse, showScrollDown]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: "Copied to clipboard",
        description: "The message has been copied to your clipboard.",
        duration: 3000,
      });
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast({
        title: "Copy failed",
        description: "Failed to copy the message. Please try again.",
        variant: "destructive",
        duration: 3000,
      });
    });
  };

  const renderMessage = (content) => (
    <ReactMarkdown
      className='markdown-content'
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <div className="relative h-full">
      <div ref={containerRef} className="overflow-y-auto h-full pb-16">
        {displayedMessages.map((msg, index) => (
          <React.Fragment key={index}>
            <div className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`flex ${msg.role === 'user' ? 'flex-row-reverse' : 'flex-row'} items-start max-w-[70%] overflow-x-auto`}>
                <Avatar className="w-10 h-10 flex-shrink-0">
                  <AvatarFallback>{msg.role === 'user' ? '' : <FontAwesomeIcon icon={faBolt} />}</AvatarFallback>
                </Avatar>
                <div className={`mx-2 p-3 rounded-lg ${msg.role === 'user' ? 'bg-gray-900 text-white' : 'bg-gray-200'} overflow-hidden relative group`}>
                  <div className={`prose prose-sm max-w-[100%] ${msg.role === 'assistant' ? 'pr-8' : ''}`}>
                    {renderMessage(msg.content)}
                  </div>
                  {msg.role === 'assistant' && (
                    <Button
                      className="absolute top-2 right-2 opacity-20 group-hover:opacity-100 transition-opacity duration-200"
                      size="icon"
                      variant="ghost"
                      onClick={() => copyToClipboard(msg.content)}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={`flex ${msg.role === 'user' ? 'justify-end mr-12' : 'justify-start ml-12'} text-xs text-muted-foreground mb-4`}>
              {msg?.timestamp && formatDistanceToNow(new Date(msg.timestamp), { addSuffix: true })}
            </div>
          </React.Fragment>
        ))}
        {(isTyping || streamedResponse) && (
          <div className="flex justify-start mb-4">
            <div className="flex flex-row items-start max-w-[70%] overflow-x-auto">
              <Avatar className="w-10 h-10 flex-shrink-0">
                <AvatarFallback>
                  <FontAwesomeIcon 
                    icon={faBolt} 
                    className={isWaiting ? 'animate-spin' : ''}
                  />
                </AvatarFallback>
              </Avatar>
              <div className="mx-2 p-3 rounded-lg bg-gray-200 overflow-hidden">
                <div className="prose prose-sm max-w-[100%] break-words">
                  {isWaiting ? 'Thinking...' : renderMessage(streamedResponse)}
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {showScrollDown && (
        <Button
          className="absolute bottom-4 right-4 rounded-full shadow-lg"
          size="icon"
          variant="secondary"
          onClick={scrollToBottom}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      )}
      {showScrollUp && (
        <Button
          className="absolute top-4 right-4 rounded-full shadow-lg"
          size="icon"
          variant="secondary"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </Button>
      )}
    </div>
  );
};

export default MessageList;