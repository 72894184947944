import React from 'react';
import { Card, CardContent } from "../components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "../components/ui/avatar";

const AssistantDetails = ({ assistant }) => {
  if (!assistant) return null;

  return (
    <Card className="mt-4 bg-gray-200">
      <CardContent className="flex items-center space-x-4 p-4">
        <div className="flex items-center space-x-4">
          <Avatar>
            <AvatarImage src={assistant.avatarUrl} alt={assistant.name} />
            <AvatarFallback>{assistant.name.charAt(0)}</AvatarFallback>
          </Avatar>
          <h3 className="text-lg font-semibold text-inter">{assistant.name}</h3>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-600">{assistant.description}</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default AssistantDetails;