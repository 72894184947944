import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUserChatbot, updateUserChatbot } from '../../slices/userChatbotSlice';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Card } from '../../components/ui/card';
import { Label } from '../../components/ui/label';
import { useToast } from '../../components/ui/use-toast';

const initialFormData = {
  name: '',
  description: '',
  avatarUrl: '',
  instructions: '',
  prompt: '',
  suggested_prompts: [''],
};

const UserChatbotForm = ({ chatbot, onSubmitSuccess }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const { toast } = useToast();

  useEffect(() => {
    if (chatbot) {
      setFormData({
        name: chatbot.name || '',
        description: chatbot.description || '',
        avatarUrl: chatbot.avatarUrl || '',
        instructions: chatbot.instructions || '',
        prompt: chatbot.prompt || '',
        suggested_prompts: chatbot.suggested_prompts && chatbot.suggested_prompts.length > 0 ? chatbot.suggested_prompts : [''],
      });
    } else {
      setFormData(initialFormData);
    }
  }, [chatbot]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePromptChange = (index, value) => {
    const newPrompts = [...formData.suggested_prompts];
    newPrompts[index] = value;
    setFormData({ ...formData, suggested_prompts: newPrompts });
  };

  const handleAddPrompt = () => {
    setFormData({
      ...formData,
      suggested_prompts: [...formData.suggested_prompts, ''],
    });
  };

  const handleRemovePrompt = (index) => {
    const newPrompts = formData.suggested_prompts.filter((_, i) => i !== index);
    setFormData({ ...formData, suggested_prompts: newPrompts });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prepare data for submission, excluding empty fields
    const submissionData = {
      name: formData.name,
      description: formData.description,
      instructions: formData.instructions,
      prompt: formData.prompt,
    };

    // Only include avatarUrl if it's not empty
    if (formData.avatarUrl.trim()) {
      submissionData.avatarUrl = formData.avatarUrl.trim();
    }

    // Only include non-empty suggested prompts
    const nonEmptyPrompts = formData.suggested_prompts.filter(prompt => prompt.trim() !== '');
    if (nonEmptyPrompts.length > 0) {
      submissionData.suggested_prompts = nonEmptyPrompts;
    }

    try {
        let result;
        if (chatbot) {
          result = await dispatch(updateUserChatbot({ id: chatbot._id, data: submissionData })).unwrap();
        } else {
          result = await dispatch(createUserChatbot(submissionData)).unwrap();
        }
        onSubmitSuccess(result);
      } catch (error) {
        console.error('Failed to save assistant:', error);
        toast({
            title: "Error",
            description: `Failed to ${chatbot ? 'update' : 'create'} the assistant. Please try again.`,
            variant: "destructive",
          });
      }
  };

  return (
    <Card className="px-10 py-4">
      <h2 className="text-2xl font-bold mb-4">{chatbot ? 'Edit Assistant' : 'Create New Assistant'}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Label htmlFor="name" className="font-semibold">Assistant's Name</Label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter a name for your assistant"
            required
          />
        </div>

        <div>
          <Label htmlFor="description" className="font-semibold">Description</Label>
          <Textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Describe your assistant's purpose and capabilities"
            required
          />
        </div>

        <div>
          <Label htmlFor="avatarUrl" className="font-semibold">Avatar URL (optional)</Label>
          <Input
            id="avatarUrl"
            name="avatarUrl"
            value={formData.avatarUrl}
            onChange={handleChange}
            placeholder="Enter a URL for your assistant's avatar image"
          />
        </div>

        

        <div>
          <Label htmlFor="prompt" className="font-semibold">Assistant's Training</Label>
          <Textarea
            id="prompt"
            name="prompt"
            value={formData.prompt}
            onChange={handleChange}
            placeholder="Enter the custom instructions and/or additional details for your assistant"
            maxLength={2000}
          />
          <p className="text-sm text-right">{formData.prompt.length}/2000</p>
        </div>

        <div>
          <Label htmlFor="instructions" className="font-semibold">User Instructions</Label>
          <Textarea
            id="instructions"
            name="instructions"
            value={formData.instructions}
            onChange={handleChange}
            placeholder="Provide instructions for users on how to interact with your assistant"
          />
        </div>

        <div>
          <Label className="font-semibold">Suggested Prompts (optional)</Label>
          {formData.suggested_prompts.map((prompt, index) => (
            <div key={index} className="flex mb-2">
              <Input
                value={prompt}
                onChange={(e) => handlePromptChange(index, e.target.value)}
                placeholder={`Suggested prompt ${index + 1}`}
                className="flex-grow mr-2"
              />
              <Button type="button" onClick={() => handleRemovePrompt(index)} variant="destructive">
                Remove
              </Button>
            </div>
          ))}
          <Button type="button" onClick={handleAddPrompt} variant="outline">
            Add Suggested Prompt
          </Button>
        </div>
          <div className='h-4'></div>
        <Button type="submit">{chatbot ? 'Update Assistant' : 'Create Assistant'}</Button>
      </form>
    </Card>
  );
};

export default UserChatbotForm;