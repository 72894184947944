import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserChatbots, setActiveChatbot, deleteUserChatbot } from '../slices/userChatbotSlice';
import UserChatbotList from './UserChatbotComponents/UserChatbotList';
import UserChatbotDetails from './UserChatbotComponents/UserChatbotDetails';
import UserChatbotForm from './UserChatbotComponents/UserChatbotForm';
import { Card } from '../components/ui/card';
import { useToast } from '../components/ui/use-toast';

const UserChatbotPage = () => {
    const dispatch = useDispatch();
    const { chatbots, activeChatbot, status, error } = useSelector((state) => state.userChatbots);
    const [isEditing, setIsEditing] = useState(false);
    const { toast } = useToast();
  
    useEffect(() => {
      if (status === 'idle') {
        dispatch(fetchUserChatbots());
      }
    }, [status, dispatch]);
  
    const handleChatbotSelect = (chatbot) => {
      dispatch(setActiveChatbot(chatbot));
      setIsEditing(false);
    };
  
    const handleEditClick = () => {
      setIsEditing(true);
    };
  
    const handleNewChatbot = () => {
      dispatch(setActiveChatbot(null));
      setIsEditing(true);
    };
  
    const handleDeleteChatbot = (id) => {
        dispatch(deleteUserChatbot(id))
          .unwrap()
          .then(() => {
            if (activeChatbot && activeChatbot._id === id) {
              dispatch(setActiveChatbot(null));
            }
            dispatch(fetchUserChatbots());
            toast({
                title: "Chatbot Deleted",
                description: "The chatbot has been successfully deleted.",
              });
          })
          .catch((error) => {
            console.error('Failed to delete chatbot:', error);
            toast({
                title: "Error",
                description: "Failed to delete the chatbot. Please try again.",
                variant: "destructive",
              });
          });
      };
    
    const handleSubmitSuccess = (newChatbot) => {
    setIsEditing(false);
    dispatch(setActiveChatbot(newChatbot));
    dispatch(fetchUserChatbots());
    toast({
        title: isEditing ? "Chatbot Updated" : "Chatbot Created",
        description: `The chatbot "${newChatbot.name}" has been successfully ${isEditing ? 'updated' : 'created'}.`,
      });
    };
  
    return (
      <div className="flex h-screen overflow-hidden">
        <Card className="w-1/3 px-4 overflow-y-auto border-0 bg-gray-100 mr-10 pr-10 border-r rounded-none">
          {status === 'loading' && <p>Loading...</p>}
          {status === 'failed' && <p>Error: {error}</p>}
          {status === 'succeeded' && (
            <UserChatbotList
              chatbots={chatbots}
              activeChatbot={activeChatbot}
              onChatbotSelect={handleChatbotSelect}
              onNewChatbot={handleNewChatbot}
              onDeleteChatbot={handleDeleteChatbot}
            />
          )}
        </Card>
        <Card className="w-2/3 pr-4 pt-2 overflow-y-auto border-0 bg-gray-100">
        {activeChatbot && !isEditing ? (
          <UserChatbotDetails
            chatbot={activeChatbot}
            onEditClick={handleEditClick}
            onDeleteChatbot={handleDeleteChatbot}
          />
        ) : (
          <UserChatbotForm 
            chatbot={isEditing ? activeChatbot : null} 
            onSubmitSuccess={handleSubmitSuccess} 
          />
        )}
      </Card>
      </div>
    );
  };
  
  export default UserChatbotPage;
  