import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateImage, fetchUserImages } from "../slices/imageGenerationSlice";
import { fetchItems } from "../slices/genericItemsSlice";
import AIImageDisplay from "./FlowFormComponents/AIImageDisplay";
import ImageHistorySidebar from "./ImageGenerationComponents/ImageHistorySidebar";
import SocialShareComponent from "./ImageGenerationComponents/SocialShareComponent";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faExpandAlt,
  faPaintBrush,
  faInfoCircle,
  faBolt,
  faBrush,
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

const AIImageGenerationPage = () => {
  const dispatch = useDispatch();
  const {
    currentImage,
    isLoading,
    error: imageError,
  } = useSelector((state) => state.imageGeneration);
  const {
    items,
    isLoading: stylesLoading,
    error: stylesError,
  } = useSelector((state) => state.genericItems);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedSize, setSelectedSize] = useState("1024x1024");
  const [selectedStyle, setSelectedStyle] = useState("no-style");
  const [prompt, setPrompt] = useState("");
  const [formError, setFormError] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleGenerateImage(e);
    }
  };

  useEffect(() => {
    dispatch(fetchUserImages());
    dispatch(fetchItems("ImageStyle"));
  }, [dispatch]);

  const handleGenerateImage = (e) => {
    e.preventDefault();

    if (!selectedSize || !prompt.trim()) {
      console.error("Validation failed:", {
        selectedSize,
        prompt: prompt.trim(),
      });
      setFormError(
        "Please select a size and enter a prompt before generating an image."
      );
      return;
    }

    setFormError("");
    const styleId = selectedStyle === "no-style" ? "" : selectedStyle;
    dispatch(
      generateImage({
        prompt,
        size: selectedSize,
        styleId,
        styleModelName: "ImageStyle",
      })
    );
  };

  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sizeOptions = [
    { value: "1024x1024", label: "1024x1024 (square)" },
    { value: "1792x1024", label: "1792x1024 (landscape)" },
    { value: "1024x1792", label: "1024x1792 (portrait)" },
  ];

  return (
    <div className="flex h-[calc(100vh-2rem)]">
      <ImageHistorySidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex-grow flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h1 className="text-inter font-bold text-2xl">AI Image Generation</h1>
          <Button onClick={toggleSidebar} size="icon" variant="outline">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faChevronLeft : faChevronRight}
            />
          </Button>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          <AnimatePresence>
            {currentImage && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-4"
              >
                <AIImageDisplay imageUrl={currentImage.imageUrl} />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {(imageError || stylesError || formError) && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-4"
              >
                <Alert variant="destructive">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>
                    {imageError || stylesError || formError}
                  </AlertDescription>
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <form
          onSubmit={handleGenerateImage}
          className="p-4 border-t bg-gray-50"
        >
          <div className="flex mb-4 space-x-4">
            <div className="flex-1">
              <label className="flex items-center text-sm font-medium text-gray-700 mb-2">
                <FontAwesomeIcon
                  icon={faExpandAlt}
                  className="mr-2 text-indigo-500"
                />
                Image Size
              </label>
              <Select value={selectedSize} onValueChange={setSelectedSize}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select size" />
                </SelectTrigger>
                <SelectContent>
                  {sizeOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex-1">
              <label className="flex items-center text-sm font-medium text-gray-700 mb-2">
                <FontAwesomeIcon
                  icon={faBrush}
                  className="mr-2 text-indigo-500"
                />
                Image Style
              </label>
              <Select
                value={selectedStyle}
                onValueChange={setSelectedStyle}
                disabled={stylesLoading}
              >
                <SelectTrigger className="w-full">
                  <SelectValue
                    placeholder={
                      stylesLoading ? "Loading styles..." : "Select style"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="no-style">No style</SelectItem>
                  {items["ImageStyle"] &&
                    items["ImageStyle"].map((style) => (
                      <SelectItem key={style._id} value={style._id}>
                        <div className="flex items-center justify-between w-full">
                          <span>{style.name}</span>
                          {style.description && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <p className="ml-2 text-xs text-gray-500">
                                    {style.description}
                                  </p>
                                </TooltipTrigger>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="prompt"
              className="flex items-center text-sm font-medium text-gray-700 mb-2"
            >
              <FontAwesomeIcon
                icon={faPaintBrush}
                className="mr-2 text-indigo-500"
              />
              Image Prompt
            </label>
            <textarea
              id="prompt"
              name="prompt"
              rows="3"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md p-2 px-4"
              placeholder="Enter your image prompt here..."
              value={prompt}
              onChange={handlePromptChange}
              onKeyDown={handleKeyDown}
            ></textarea>
          </div>
          <Button type="submit" disabled={isLoading} className="w-full">
            <FontAwesomeIcon
              icon={faBolt}
              className={`mr-2 ${isLoading ? "animate-spin" : ""}`}
            />
            {isLoading ? "Generating..." : "Generate Image"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AIImageGenerationPage;
