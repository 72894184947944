// src/slices/appFeaturesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItems } from './genericItemsSlice';

const modelsToPrefetch = ['ImageStyle']; // Add more model names as needed

export const fetchAppFeatures = createAsyncThunk(
  'appFeatures/fetchAppFeatures',
  async (_, { dispatch }) => {
    await Promise.all(
      modelsToPrefetch.map(modelName => dispatch(fetchItems(modelName)))
    );
  }
);

const appFeaturesSlice = createSlice({
  name: 'appFeatures',
  initialState: {
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppFeatures.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAppFeatures.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAppFeatures.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default appFeaturesSlice.reducer;