import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFlow, fetchGlobalFlows, fetchFlowById } from '../slices/flowsSlice';
import { useNavigate } from 'react-router-dom';
import { useToast } from "../components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { ScrollArea } from "../components/ui/scroll-area";
import { PlusCircle, FileJson, Search, ChevronUp, ChevronDown, ChevronLeft, InfoIcon, Folder, ArrowLeft, Menu, Clock, Play, Hash, Layers, Tag  } from 'lucide-react';
import FlowForm from './FlowForm';
import JsonInputForm from './FlowFormComponents/JsonInputForm';
import { Alert, AlertDescription } from "../components/ui/alert";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";
import { Badge } from "../components/ui/badge";

const FlowCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPreMadeFlow, setSelectedPreMadeFlow] = useState(null);
  const [isLoadingPreMadeFlow, setIsLoadingPreMadeFlow] = useState(false);
  const [preMadeFlowError, setPreMadeFlowError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTemplateSelectionExpanded, setIsTemplateSelectionExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState('standard');
  const [isNavExpanded, setIsNavExpanded] = useState(true);

  const globalFlows = useSelector(state => state.flows.globalFlows);
  const globalFlowsStatus = useSelector(state => state.flows.globalFlowsStatus);
  const { selectedTeam, teams } = useSelector((state) => state.teams);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const InfoBlurb = ({ children }) => (
    <div className="flex items-start space-x-2 text-sm text-gray-500 mt-4">
      <span>{children}</span>
    </div>
  );

  const NavButton = ({ icon: Icon, label, tabName }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={activeTab === tabName ? 'default' : 'ghost'}
            className={`w-full justify-start mb-2 ${!isNavExpanded ? 'px-2' : ''}`}
            onClick={() => setActiveTab(tabName)}
          >
            <Icon className="w-4 h-4 mr-2" />
            {isNavExpanded && <span>{label}</span>}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right" align="start">
          {label}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  const getInfoBlurb = () => {
    switch (activeTab) {
      case 'standard':
        return "The manual step-by-step builder gives you complete control over your flow creation process. This intuitive interface allows you to add individual actions and requests, customize each step with specific parameters and conditions, and visualize the flow as you build it.";
      case 'pre-made':
        return "Our library of pre-made templates offers a quick start for common business processes. You can choose from a variety of industry-standard workflow templates and customize them to fit your specific needs.";
      case 'json':
        return "For advanced users or those with pre-existing flow definitions, we offer the ability to input raw JSON. This method allows you to paste or upload your flow definition in JSON format, enabling quick import of complex flows with multiple steps and conditions.";
      default:
        return "";
    }
  };

  const selectedTeamInfo = useMemo(() => {
    if (selectedTeam === 'all') {
      return { name: 'Personal', id: 'all' };
    } else if (selectedTeam === 'personal') {
      return { name: 'Personal', id: 'personal' };
    } else {
      const teamRecord = teams.find(team => team._id === selectedTeam);
      return teamRecord ? { name: teamRecord.name, id: teamRecord._id } : null;
    }
  }, [teams, selectedTeam]);

  useEffect(() => {
    if (globalFlowsStatus === 'idle') {
      dispatch(fetchGlobalFlows());
    }
  }, [globalFlowsStatus, dispatch]);

  const handleSubmit = async (flowData) => {
    setIsSubmitting(true);
    try {
      let flowDataWithTeam = { ...flowData };
      if (selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal') {
        flowDataWithTeam.team = selectedTeamInfo.id;
      }
      await dispatch(createFlow(flowDataWithTeam)).unwrap();
      toast({
        title: "Success",
        description: "Flow created successfully",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to create flow",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleJsonSubmit = async (jsonData) => {
    setIsSubmitting(true);
    try {
      let jsonDataWithTeam = { ...jsonData };
      if (selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal') {
        jsonDataWithTeam.team = selectedTeamInfo.id;
      }
      await dispatch(createFlow(jsonDataWithTeam)).unwrap();
      toast({
        title: "Success",
        description: "Flow created successfully from JSON",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to create flow from JSON",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePreMadeFlowSelect = async (flowId) => {
    setIsLoadingPreMadeFlow(true);
    setPreMadeFlowError(null);
    try {
      const fullFlow = await dispatch(fetchFlowById(flowId)).unwrap();
      setSelectedPreMadeFlow({
        ...fullFlow,
        name: `${fullFlow.name} (Template)`,
        team: selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal' ? selectedTeamInfo.id : null,
      });
      setIsTemplateSelectionExpanded(false);
    } catch (error) {
      setPreMadeFlowError("Failed to load the selected flow. Please try again.");
      toast({
        title: "Error",
        description: "Failed to load the selected flow",
        variant: "destructive",
      });
    } finally {
      setIsLoadingPreMadeFlow(false);
    }
  };

  const filteredFlows = globalFlows
  .filter(flow => 
    flow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    flow.description.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.name.localeCompare(b.name));

  const toggleTemplateSelection = () => {
    setIsTemplateSelectionExpanded(!isTemplateSelectionExpanded);
  };

  return (
    <div className="flex h-[calc(100vh-2rem)]">
      <ScrollArea className={`border-r transition-all duration-300 ${isNavExpanded ? 'w-1/4' : 'w-16'}`}>
        <div className="p-4 py-0">
          <div className="flex justify-between items-center mb-4">
            {isNavExpanded && <h1 className="text-2xl font-bold text-inter">Create a Flow</h1>}
            <Button variant="ghost" size="icon" onClick={toggleNav}>
              {isNavExpanded ? <ChevronLeft className="w-4 h-4" /> : <Menu className="w-4 h-4" />}
            </Button>
          </div>
          {isNavExpanded && selectedTeamInfo && (
  <Alert className="mb-4">
    <AlertDescription>
      Creating flow for: {selectedTeamInfo.name}
    </AlertDescription>
  </Alert>
)}
          <div className="space-y-2">
            <NavButton icon={PlusCircle} label="Standard Creation" tabName="standard" />
            <NavButton icon={Layers} label="Pre-made Flows" tabName="pre-made" />
            <NavButton icon={FileJson} label="JSON Input" tabName="json" />
          </div>
          {isNavExpanded && <InfoBlurb>{getInfoBlurb()}</InfoBlurb>}
        </div>
      </ScrollArea>
      <div className={`transition-all duration-300 pl-6 ${isNavExpanded ? 'w-3/4' : 'w-[calc(100%-2rem)]'}`}>
        <ScrollArea className="h-full">
          {activeTab === 'standard' && (
            <div className="p-4 py-0">
              <h2 className="text-2xl font-bold mb-4">Standard Creation</h2>
              <div className='mt-8'>
                <FlowForm 
                  onSubmit={handleSubmit} 
                  submitButtonText="Create Flow" 
                  isSubmitting={isSubmitting}
                  submittingText="Creating Flow..."
                  isEditMode={false}
                />
              </div>
            </div>
          )}
          {activeTab === 'pre-made' && (
            <Card className="m-4 mt-0">
              <CardHeader>
                <CardTitle>Pre-made Flows</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">
                      {selectedPreMadeFlow ? selectedPreMadeFlow.name : "Choose a Template"}
                    </h3>
                    {selectedPreMadeFlow && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setSelectedPreMadeFlow(null);
                          setIsTemplateSelectionExpanded(true);
                        }}
                        className="flex items-center"
                      >
                        <ArrowLeft className="w-4 h-4 mr-2" />
                        Back to Templates
                      </Button>
                    )}
                  </div>
  
                  {isTemplateSelectionExpanded && (
      <>
        <div className="flex items-center space-x-2 mb-4">
          <Search className="w-4 h-4 text-gray-400" />
          <Input
            type="text"
            placeholder="Search pre-made flows..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {filteredFlows.map((flow) => (
            <Card
              key={flow._id}
              className={`cursor-pointer transition-all duration-200 ${
                selectedPreMadeFlow && selectedPreMadeFlow._id === flow._id
                  ? 'border-blue-500 shadow-lg'
                  : 'hover:shadow-md'
              }`}
              onClick={() => handlePreMadeFlowSelect(flow._id)}
            >
              <CardContent className="pt-4">
                <h4 className="font-semibold text-lg mb-1">{flow.name}</h4>
                {flow.flowCategory && (
                  <div className="flex items-center mb-2 text-sm text-gray-500">
                    <Folder className="w-3 h-3 mr-1" />
                    <span>{flow.flowCategory}</span>
                  </div>
                )}
                <p className="text-sm text-gray-600 mb-3">{flow.description}</p>
                {flow.tags && flow.tags.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-3">
                    {flow.tags.map((tag, index) => (
                      <Badge key={index} variant="secondary" className="text-xs">
                        <Tag className="w-3 h-3 mr-1" />
                        {tag}
                      </Badge>
                    ))}
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2 text-xs text-gray-500">
                  {/*<div className="flex items-center">
                    <Play className="w-3 h-3 mr-1" />
                    <span>{flow.executionCount || 0} executions</span>
                  </div>
                  <div className="flex items-center">
                    <Clock className="w-3 h-3 mr-1" />
                    <span>{flow.averageExecutionTime || 'N/A'} avg</span>
                  </div>*/}
                  <div className="flex items-center">
                    <Layers className="w-3 h-3 mr-1" />
                    <span>{(flow.steps && flow.steps.length) || 0} steps</span>
                  </div>
                  <div className="flex items-center">
                    <Hash className="w-3 h-3 mr-1" />
                    <span>{(flow.tags && flow.tags.length) || 0} tags</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </>
    )}
  
                  {isLoadingPreMadeFlow && <p>Loading pre-made flow...</p>}
                  {preMadeFlowError && (
                    <Alert variant="destructive">
                      <AlertDescription>{preMadeFlowError}</AlertDescription>
                    </Alert>
                  )}
                  {selectedPreMadeFlow && !isLoadingPreMadeFlow && (
                    <FlowForm 
                      initialFlow={selectedPreMadeFlow}
                      onSubmit={handleSubmit} 
                      submitButtonText="Create Flow from Template" 
                      isSubmitting={isSubmitting}
                      submittingText="Creating Flow..."
                      isEditMode={false}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          )}
          {activeTab === 'json' && (
            <Card className="m-4 mt-0">
              <CardHeader>
                <CardTitle>JSON Input</CardTitle>
              </CardHeader>
              <CardContent>
                <JsonInputForm
                  onSubmit={handleJsonSubmit}
                  isSubmitting={isSubmitting}
                />
                <a 
                  href="/JSON_Flow_Example.txt" 
                  download 
                  className="text-blue-600 hover:text-blue-800 underline mt-2 inline-block"
                >
                  Download JSON example
                </a>
              </CardContent>
            </Card>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};

export default FlowCreate;