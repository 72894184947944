import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchExecutions = createAsyncThunk(
  'executions/fetchExecutions',
  async ({ page, limit, teamId, search, status, sortBy, sortOrder }, { rejectWithValue }) => {
    try {
      const response = await api.fetchExecutions({ page, limit, teamId, search, status, sortBy, sortOrder });
      console.log('Executions fetched:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const fetchExecutionById = createAsyncThunk('executions/fetchExecutionById', async (id) => {
  const response = await api.fetchExecutionById(id);
  console.log('Execution fetched in slice:', response.data);
  return response.data;
});

export const startExecution = createAsyncThunk(
    'executions/startExecution',
    async (flowId, { rejectWithValue }) => {
      try {
        const response = await api.startExecution(flowId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

// Update this thunk to use the new API function
export const fetchExecutionStatus = createAsyncThunk(
  'executions/fetchExecutionStatus',
  async (executionId, { rejectWithValue }) => {
    try {
      const response = await api.fetchExecutionStatus(executionId);
      return response.data;
    } catch (error) {
      console.error('Error fetching execution status:', error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const updateExecutionInList = createAsyncThunk(
  'executions/updateExecutionInList',
  async (updatedExecution, { getState, dispatch }) => {
    const { executions } = getState().executions;
    const updatedExecutions = executions.map(execution => 
      execution._id === updatedExecution._id ? updatedExecution : execution
    );
    return updatedExecutions;
  }
);

const executionsSlice = createSlice({
  name: 'executions',
  initialState: {
    executions: [],
    currentExecution: null,
    status: 'idle',
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalExecutions: 0,
  },
  reducers: {
    executionUpdated(state, action) {
      const { id, ...updateData } = action.payload;
      const index = state.executions.findIndex(e => e.id === id);
      if (index !== -1) {
        state.executions[index] = { ...state.executions[index], ...updateData };
      }
      if (state.currentExecution && state.currentExecution.id === id) {
        state.currentExecution = { ...state.currentExecution, ...updateData };
      }
    },
    clearCurrentExecution(state) {
      state.currentExecution = null;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchExecutions.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchExecutions.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.executions = action.payload.executions || [];
      state.totalExecutions = action.payload.totalExecutions || 0;
      state.currentPage = action.payload.currentPage || 1;
      state.totalPages = action.payload.totalPages || 1;
      if (action.payload.totalPages) state.totalPages = action.payload.totalPages;
        if (action.payload.totalExecutions) state.totalExecutions = action.payload.totalExecutions;
    })
    .addCase(fetchExecutions.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload || 'Failed to fetch executions';
    })
      .addCase(fetchExecutionById.fulfilled, (state, action) => {
        state.currentExecution = action.payload;
      })
      .addCase(startExecution.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(startExecution.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentExecution = action.payload;
      })
      .addCase(startExecution.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchExecutionStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExecutionStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.currentExecution) {
          // Preserve flow and steps information
          const preservedFlow = state.currentExecution.flow;
          state.currentExecution = {
            ...state.currentExecution,
            ...action.payload,
            flow: {
              ...preservedFlow,
              ...action.payload.flow
            }
          };
          
          // Update stepResults while preserving step information
          if (Array.isArray(action.payload.stepResults)) {
            state.currentExecution.stepResults = action.payload.stepResults.map((newStep, index) => {
              const oldStep = state.currentExecution.stepResults[index] || {};
              return {
                ...oldStep,
                ...newStep,
                name: oldStep.name || newStep.name,
                type: oldStep.type || newStep.type,
                prompt: oldStep.prompt || newStep.prompt,
                description: oldStep.description || newStep.description
              };
            });
          }
        } else {
          state.currentExecution = action.payload;
        }
      })
      .addCase(fetchExecutionStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch execution status';
      })
      .addCase(updateExecutionInList.fulfilled, (state, action) => {
        const updatedExecution = action.payload;
        state.executions = state.executions.map(execution => 
          execution._id === updatedExecution._id ? { ...execution, ...updatedExecution } : execution
        );
        if (state.currentExecution && state.currentExecution._id === updatedExecution._id) {
          state.currentExecution = { ...state.currentExecution, ...updatedExecution };
        }
      });
  },
});

export const { executionUpdated, clearCurrentExecution } = executionsSlice.actions;
export default executionsSlice.reducer;