// src/pages/ChatComponents/NewChatInstructions.js

import React from 'react';
import { Card, CardContent } from "../../components/ui/card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faComments, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const NewChatInstructions = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <Card className="w-2/3 max-w-2xl">
        <CardContent className="pt-6">
          <h2 className="text-2xl font-bold text-center mb-6">Start a New Conversation</h2>
          <div className="space-y-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-primary text-primary-foreground rounded-full mr-4">
                <FontAwesomeIcon icon={faRobot} size="lg" />
              </div>
              <div>
                <h3 className="text-lg font-semibold">1. Select an Assistant</h3>
                <p className="text-sm text-muted-foreground">Choose an AI assistant that best fits your needs.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-primary text-primary-foreground rounded-full mr-4">
                <FontAwesomeIcon icon={faComments} size="lg" />
              </div>
              <div>
                <h3 className="text-lg font-semibold">2. Name Your Conversation</h3>
                <p className="text-sm text-muted-foreground">Give your chat a memorable title to easily find it later.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center bg-primary text-primary-foreground rounded-full mr-4">
                <FontAwesomeIcon icon={faPaperPlane} size="lg" />
              </div>
              <div>
                <h3 className="text-lg font-semibold">3. Send Your First Message</h3>
                <p className="text-sm text-muted-foreground">Type your question or prompt and hit send to begin the conversation.</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewChatInstructions;