import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchFlowById, updateFlow } from '../slices/flowsSlice';
import { useToast } from "../components/ui/use-toast";
import FlowForm from './FlowForm';

const FlowEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const flow = useSelector(state => state.flows.selectedFlow);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(fetchFlowById(id));
  }, [dispatch, id]);

  const handleSubmit = async (flowData) => {
    setIsSubmitting(true);
    try {
      await dispatch(updateFlow({ id, flowData })).unwrap();
      toast({
        title: "Success",
        description: "Flow updated successfully",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to update flow",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-2">
      <h1 className="text-3xl font-bold">Edit Flow</h1>
      <FlowForm
        initialFlow={flow}
        onSubmit={handleSubmit}
        submitButtonText="Update Flow"
        isSubmitting={isSubmitting}
        submittingText="Updating..."
        isEditMode={true}
      />
    </div>
  );
};

export default FlowEdit;