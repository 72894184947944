import React, { useState, useRef } from 'react';
import { Button } from "../../components/ui/button";
import { Download, Copy, Expand, X, Link } from 'lucide-react';
import { useToast } from "../../components/ui/use-toast";

const AIImageDisplay = ({ imageUrl }) => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const { toast } = useToast();
    const canvasRef = useRef(null);

    const handleDownload = async () => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'ai-generated-image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            toast({
                title: "Download Failed",
                description: "Failed to download image: " + error,
                variant: "destructive",
                duration: 3000,
            });
        }
    };

    const copyImageToClipboard = async () => {
        try {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                const canvas = canvasRef.current;
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(async (blob) => {
                    try {
                        await navigator.clipboard.write([
                            new ClipboardItem({ 'image/png': blob })
                        ]);
                        toast({
                            title: "Image Copied",
                            description: "The image has been copied to your clipboard.",
                            duration: 2000,
                        });
                    } catch (err) {
                        toast({
                            title: "Copy Failed",
                            description: "Failed to copy image: " + err,
                            variant: "destructive",
                            duration: 3000,
                        });
                    }
                }, 'image/png');
            };
            img.src = imageUrl;
        } catch (error) {
            toast({
                title: "Copy Failed",
                description: "Failed to copy image: " + error,
                variant: "destructive",
                duration: 3000,
            });
        }
    };

    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(imageUrl).then(() => {
            toast({
                title: "URL Copied",
                description: "The image URL has been copied to your clipboard.",
                duration: 2000,
            });
        }).catch((err) => {
            toast({
                title: "Copy Failed",
                description: "Failed to copy URL: " + err,
                variant: "destructive",
                duration: 3000,
            });
        });
    };

    const ActionButton = ({ onClick, children }) => (
        <Button
            onClick={onClick}
            className="flex items-center space-x-2 bg-gray-200 hover:bg-gray-300 text-gray-800 transition-colors duration-200"
        >
            {children}
        </Button>
    );

    return (
        <div className="mt-4 space-y-4">
            <div className="relative">
                <img 
                    src={imageUrl} 
                    alt="AI Generated Image" 
                    className="max-w-full h-auto max-h-96 object-contain rounded-lg shadow-lg"
                />
                <Button 
                    variant="outline" 
                    size="sm" 
                    className="absolute top-2 right-2 bg-white hover:bg-gray-100"
                    onClick={() => setIsLightboxOpen(true)}
                >
                    <Expand className="h-4 w-4" />
                </Button>
            </div>
            <div className="flex space-x-2">
                <ActionButton onClick={handleDownload}>
                    <Download className="h-4 w-4" />
                    <span>Download</span>
                </ActionButton>
                <ActionButton onClick={copyImageToClipboard}>
                    <Copy className="h-4 w-4" />
                    <span>Copy Image</span>
                </ActionButton>
                <ActionButton onClick={copyUrlToClipboard}>
                    <Link className="h-4 w-4" />
                    <span>Copy URL</span>
                </ActionButton>
            </div>

            {isLightboxOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="relative bg-white p-2 rounded-lg max-w-[90vw] max-h-[90vh]">
                        <img 
                            src={imageUrl} 
                            alt="AI Generated Image (Lightbox)" 
                            className="max-w-full max-h-[calc(90vh-4rem)] object-contain"
                        />
                        <Button 
                            variant="outline" 
                            size="sm" 
                            className="absolute top-3 right-3 bg-white hover:bg-gray-100"
                            onClick={() => setIsLightboxOpen(false)}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            )}
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default AIImageDisplay;