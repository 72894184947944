import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { fetchSavedAssets, deleteSavedAsset } from "../slices/savedAssetsSlice";
import {
  fetchNotifications,
  markNotificationAsRead,
  markNotificationAsUnread,
  archiveNotification,
  markAllNotificationsAsRead,
  archiveAllNotifications,
} from "../slices/notificationsSlice";
import { Button } from "../components/ui/button";
import { Copy, Check, RefreshCw, Trash2, Archive, Circle } from "lucide-react";
import AIImageDisplay from "./FlowFormComponents/AIImageDisplay";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import ActionButton from "../components/ActionButton";
import { ActionStepType } from "../pages/FlowFormComponents/stepTypes";

const stringifyContent = (content) => {
  if (typeof content === "string") {
    return content;
  }
  if (typeof content === "object") {
    return JSON.stringify(content, null, 2);
  }
  return String(content);
};

const MarkdownContent = ({ content }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    components={{
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter
            style={vscDarkPlus}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
    }}
    className="markdown-content"
  >
    {stringifyContent(content)}
  </ReactMarkdown>
);

const AssetsList = ({
  assets,
  onSelectAsset,
  selectedAssetId,
  onDeleteAsset,
  onArchiveNotification,
  onMarkAsRead,
  onMarkAsUnread,
  assetType,
}) => (
  <div className="space-y-1">
    {assets.length === 0 ? (
      <p className="text-center text-gray-500">No assets found</p>
    ) : (
      (console.log("assets: ", assets),
      assets.map((asset) => (
        <Card
          key={asset._id}
          className={`cursor-pointer transition-colors ${
            selectedAssetId === asset._id
              ? "bg-gray-900 text-white"
              : assetType === "inbox"
              ? asset.isRead
                ? "bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
                : "bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
              : "bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          }`}
        >
          <CardContent className="p-3 flex justify-between items-center">
            <div onClick={() => onSelectAsset(asset._id)} className="flex-grow">
              <h3 className="text-lg font-semibold">
                {(asset.course && asset.course.post_title) || asset.title || asset.name || "Untitled"}
              </h3>
              <p
                className={`text-sm ${
                  selectedAssetId === asset._id
                    ? "text-gray-300"
                    : "text-gray-500"
                }`}
              >
                {new Date(asset.date).toLocaleDateString()}
              </p>
            </div>
            <div className="flex space-x-2">
              {assetType === "inbox" ? (
                <>
                  <Button
                    onClick={() =>
                      asset.isRead
                        ? onMarkAsUnread(asset._id)
                        : onMarkAsRead(asset._id)
                    }
                    variant="ghost"
                    size="sm"
                    className="p-0"
                  >
                    <Circle
                      className={`h-4 w-4 ${
                        asset.isRead
                          ? "text-gray-400"
                          : "text-green-500 fill-current"
                      }`}
                    />
                  </Button>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button variant="outline" size="sm">
                        <Archive className="h-4 w-4 text-yellow-500" />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Archive Notification
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          Are you sure you want to archive this notification?
                          This action cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() => onArchiveNotification(asset._id)}
                        >
                          Archive
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </>
              ) : (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="outline" size="sm">
                      <Trash2 className="h-4 w-4 text-red-500" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Delete Asset</AlertDialogTitle>
                      <AlertDialogDescription>
                        Are you sure you want to delete this asset? This action
                        cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => onDeleteAsset(asset._id)}
                      >
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </div>
          </CardContent>
        </Card>
      )))
    )}
  </div>
);

const AssetDetail = ({
  asset,
  assetType,
  onDeleteAsset,
  onArchiveNotification,
  onMarkAsRead,
  onMarkAsUnread,
}) => {
  const [copied, setCopied] = useState(false);

  if (!asset) {
    return (
      <p className="text-center text-gray-500 mt-10">
        Select an item to view details
      </p>
    );
  }

  const renderAssetSpecificDetails = () => {
    switch (assetType) {
      case "blog-posts":
        return (
          <>
          <div className="mt-2">
            <ActionButton
              actionType={ActionStepType.POST_TO_WORDPRESS}
              id={asset._id}
              blogPost={asset}
            />
          </div>
            <p className="mt-2">
              <strong>Status:</strong> {asset.status}
            </p>
            <p className="mt-2">
              <strong>Excerpt:</strong> {asset.excerpt}
            </p>
            <p className="mt-2">
              <strong>Categories:</strong>{" "}
              {asset.categories?.join(", ") || "None"}
            </p>
            {asset.tags?.length > 0 && (
              <p className="mt-2">
                <strong>Tags:</strong> {asset.tags.join(", ")}
              </p>
            )}
            {asset.note && (
              <p className="mt-2">
                <strong>Note:</strong> <MarkdownContent content={asset.note} />
              </p>
            )}
            {asset.title && (
              <p className="mt-2">
                <strong>Title:</strong> {asset.title}
              </p>
            )}
          </>
        );
      case "products":
        return (
          <>
          <div className="mt-2">
              <ActionButton
                actionType={ActionStepType.POST_PRODUCT_TO_WORDPRESS}
                id={asset._id}
                product={asset}
              />
            </div>
            {asset.short_description && (
              <p className="mt-2">
                <strong>Short Description:</strong> {asset.short_description}
              </p>
            )}
            {asset.images && asset.images.length > 0 && (
              <div className="mt-4">
                <p className="mb-2">
                  <strong>Product Images:</strong>
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {asset.images.map((url, index) => (
                    <AIImageDisplay key={index} imageUrl={url} />
                  ))}
                </div>
              </div>
            )}
            <p className="mt-2">
              <strong>Categories:</strong>{" "}
              {asset.categories?.join(", ") || "None"}
            </p>
            {asset.tags?.length > 0 && (
              <p className="mt-2">
                <strong>Tags:</strong> {asset.tags.join(", ")}
              </p>
            )}
            {asset.note && (
              <p className="mt-2">
                <strong>Note:</strong> <MarkdownContent content={asset.note} />
              </p>
            )}
            {asset.name && (
              <p className="mt-2">
                <strong>Product Name:</strong> {asset.name}
              </p>
            )}
            <p className="mt-2">
              <strong>Status:</strong> {asset.status}
            </p>
          </>
        );
      case "social-posts":
        return (
          <>
            {/*<div className="my-4">
              <ActionButton
                actionType={ActionStepType.POST_TO_INSTAGRAM}
                id={asset._id}
              />
            </div>*/}
            <p className="mt-2">
              <strong>Platform:</strong> {asset.platform}
            </p>
            <p className="mt-2">
              <strong>Status:</strong> {asset.status}
            </p>
            <p className="mt-2">
              <strong>Hashtags:</strong> {asset.hashtags?.join(", ") || "None"}
            </p>
            <p className="mt-2">
              <strong>Mentions:</strong> {asset.mentions?.join(", ") || "None"}
            </p>
            {asset.scheduledDate && (
              <p className="mt-2">
                <strong>Scheduled Date:</strong>{" "}
                {new Date(asset.scheduledDate).toLocaleString()}
              </p>
            )}
            {asset.mediaUrls && asset.mediaUrls.length > 0 && (
              <div className="mt-4">
                <p className="mb-2">
                  <strong>Media:</strong>
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {asset.mediaUrls.map((url, index) => (
                    <AIImageDisplay key={index} imageUrl={url} />
                  ))}
                </div>
              </div>
            )}
          </>
        );
      case "general-assets":
        return (
          <div className="mt-2">
            {asset.tags?.length > 0 && (
              <p className="mt-2">
                <strong>Tags:</strong> {asset.tags.join(", ")}
              </p>
            )}
            {asset.note && (
              <p className="mt-2">
                <strong>Note:</strong> <MarkdownContent content={asset.note} />
              </p>
            )}
            {asset.title && (
              <p className="mt-2">
                <strong>Title:</strong> {asset.title}
              </p>
            )}
          </div>
        );
      case "general-images":
        return (
          <>
            <p className="mt-2">
              {asset.tags?.length > 0 && (
                <p className="mt-2">
                  <strong>Tags:</strong> {asset.tags.join(", ")}
                </p>
              )}
              {asset.note && (
                <p className="mt-2">
                  <strong>Note:</strong>{" "}
                  <MarkdownContent content={asset.note} />
                </p>
              )}
              {asset.title && (
                <p className="mt-2">
                  <strong>Title:</strong> {asset.title}
                </p>
              )}
            </p>
            <AIImageDisplay imageUrl={asset.imageUrl} />
          </>
        );
      case "inbox":
        return (
          <>
            {asset?.type && (
              <p className="mt-2 capitalize">
                <strong>Message Type:</strong> {asset.type}
              </p>
            )}
            {asset?.relatedId && (
              <>
                <p className="mt-2 capitalize">
                  <strong>Related To:</strong> {asset.relatedTo}
                </p>
                <p className="mt-2">
                  <strong>Related ID:</strong> {asset.relatedId}
                </p>
              </>
            )}
          </>
        );
      case "courses":
        return (
          <>
            {asset.course && (
              <>
              <div className="mt-2">
              <ActionButton
                actionType={ActionStepType.POST_COURSE_TO_TUTORLMS}
                id={asset._id}
                savedCourse={asset}
              />
            </div>
                <p className="mt-2">
                  <strong>Course Title:</strong> {asset.course.post_title}
                </p>
                <p className="mt-2">
                  <strong>Course Content:</strong>{" "}
                  <MarkdownContent content={asset.course.post_content} />
                </p>
                <p className="mt-2">
                  <strong>Course Level:</strong> {asset.course.course_level}
                </p>
                {asset.course.course_categories &&
                  asset.course.course_categories.length > 0 && (
                    <p className="mt-2">
                      <strong>Categories:</strong>{" "}
                      {asset.course.course_categories.join(", ")}
                    </p>
                  )}
                {asset.course.course_tags &&
                  asset.course.course_tags.length > 0 && (
                    <p className="mt-2">
                      <strong>Tags:</strong>{" "}
                      {asset.course.course_tags.join(", ")}
                    </p>
                  )}
              </>
            )}
            {asset.topics && asset.topics.length > 0 && (
              <div className="mt-4">
                <strong>Topics:</strong>
                <ul className="list-disc list-inside">
                  {asset.topics.map((topic, index) => (
                    <li key={index}>{topic.topic_title}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* Add more course-specific details as needed */}
            
          </>
        );
      default:
        return null;
    }
  };

  const handleCopy = async () => {
    const contentToCopy = asset.content || asset.text || asset.message;
    try {
      await navigator.clipboard.writeText(contentToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Card>
      <CardContent className="p-6">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-2xl font-bold">{asset.title || asset.name || (asset.course && asset.course.post_title) || "Untitled"}</h2>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-600 mr-2">
              Copy Main Content
            </span>
            <Button
              onClick={handleCopy}
              variant="outline"
              size="sm"
              className="flex items-center"
            >
              {copied ? (
                <Check className="h-4 w-4" />
              ) : (
                <Copy className="h-4 w-4" />
              )}
            </Button>
            {assetType === "inbox" ? (
              <>
                <Button
                  onClick={() =>
                    asset.isRead
                      ? onMarkAsUnread(asset._id)
                      : onMarkAsRead(asset._id)
                  }
                  variant="ghost"
                  size="sm"
                  className="p-0"
                >
                  <Circle
                    className={`h-4 w-4 ${
                      asset.isRead
                        ? "text-gray-400"
                        : "text-green-500 fill-current"
                    }`}
                  />
                </Button>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="outline" size="sm">
                      <Archive className="h-4 w-4 text-yellow-500" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Archive Notification</AlertDialogTitle>
                      <AlertDialogDescription>
                        Are you sure you want to archive this notification? This
                        action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => onArchiveNotification(asset._id)}
                      >
                        Archive
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </>
            ) : (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete Asset</AlertDialogTitle>
                    <AlertDialogDescription>
                      Are you sure you want to delete this asset? This action
                      cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={() => onDeleteAsset(asset._id)}>
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </div>
        <p className="text-sm text-gray-500 mb-2">
          {`${asset.creator?.firstName} ${asset.creator?.lastName}` ||
            "Unknown"}{" "}
          - {new Date(asset.date).toLocaleDateString()}
        </p>
        
        {renderAssetSpecificDetails()}
        {assetType !== "general-images" && 
 (asset.content || asset.text || asset.message || asset.description) && (
  <div className="my-6 p-4 bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-inner">
    <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">
      Main Content
    </h3>
    <p className="mt-4">
      <MarkdownContent
        content={
          asset.content ||
          asset.text ||
          asset.message ||
          asset.description
        }
      />
    </p>
  </div>
)}
      </CardContent>
    </Card>
  );
};

const AssetsPage = () => {
  const { assetType } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    assets,
    status: savedAssetsStatus,
    error: savedAssetsError,
  } = useSelector((state) => state.savedAssets);
  const {
    notifications,
    status: notificationsStatus,
    error: notificationsError,
  } = useSelector((state) => state.notifications);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchAssets = useCallback(() => {
    if (assetType === "inbox") {
      //dispatch(fetchNotifications());
    } else if (assetType) {
      dispatch(fetchSavedAssets(assetType));
    }
  }, [dispatch, assetType]);

  useEffect(() => {
    fetchAssets();
    setSelectedAssetId(null); // Clear selection when asset type changes
  }, [fetchAssets, setSelectedAssetId]);

  const handleSelectAsset = (id) => {
    setSelectedAssetId(id);
    if (assetType === "inbox") {
      const selectedNotification = notifications.find(
        (notif) => notif._id === id
      );
      if (selectedNotification && !selectedNotification.isRead) {
        dispatch(markNotificationAsRead(id));
      }
    }
  };

  const handleRefresh = () => {
    if (assetType === "inbox") {
      dispatch(fetchNotifications());
    } else if (assetType) {
      dispatch(fetchSavedAssets(assetType));
    }
    setSelectedAssetId(null); // Clear selection when refreshing
  };

  const handleDeleteAsset = (id) => {
    dispatch(deleteSavedAsset({ assetType, id }))
      .unwrap()
      .then(() => {
        if (selectedAssetId === id) {
          setSelectedAssetId(null);
        }
      })
      .catch((error) => {
        console.error("Failed to delete asset:", error);
      });
  };

  const handleArchiveNotification = (id) => {
    dispatch(archiveNotification(id))
      .unwrap()
      .then(() => {
        if (selectedAssetId === id) {
          setSelectedAssetId(null);
        }
      })
      .catch((error) => {
        console.error("Failed to archive notification:", error);
      });
  };

  const handleMarkAsRead = (id) => {
    dispatch(markNotificationAsRead(id));
  };

  const handleMarkAsUnread = (id) => {
    dispatch(markNotificationAsUnread(id));
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotificationsAsRead());
  };

  const handleArchiveAll = () => {
    dispatch(archiveAllNotifications());
  };

  const status =
    assetType === "inbox" ? notificationsStatus : savedAssetsStatus;
  const error = assetType === "inbox" ? notificationsError : savedAssetsError;
  const items = assetType === "inbox" ? notifications : assets;

  const filteredItems = items.filter(
    (item) =>
      (item.title &&
        item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.name &&
        item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.content &&
        item.content.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.message &&
        item.message.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.course &&
        item.course.post_title &&
        item.course.post_title
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (item.course &&
        item.course.post_content &&
        item.course.post_content
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))
  );

  const selectedAsset = items.find((item) => item._id === selectedAssetId);

  /*if (status === 'loading') {
    return <div>Loading...</div>;
  }*/

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex h-[calc(100vh-2rem)] overflow-hidden">
      <div className="w-1/3 pr-4 overflow-y-auto">
        <div className="sticky top-0 bg-transparent pb-2 z-10 space-y-2">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold capitalize text-inter font-bold">
              {assetType.replace("-", " ")}
            </h2>
            <div className="flex items-center space-x-2">
              {assetType === "inbox" && (
                <>
                  <Button
                    onClick={handleMarkAllAsRead}
                    variant="outline"
                    size="sm"
                  >
                    Mark All Read
                  </Button>
                  <Button
                    onClick={handleArchiveAll}
                    variant="outline"
                    size="sm"
                  >
                    Archive All
                  </Button>
                </>
              )}
              <Button
                variant="ghost"
                size="sm"
                onClick={handleRefresh}
                className="p-0"
              >
                <RefreshCw
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                  size={20}
                />
              </Button>
            </div>
          </div>
          <Input
            type="text"
            placeholder={`Search ${
              assetType === "inbox"
                ? "notifications"
                : assetType.replace("-", " ")
            }...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
        </div>
        {status === "loading" ? (
          <></>
        ) : (
          <AssetsList
            assets={filteredItems}
            onSelectAsset={handleSelectAsset}
            selectedAssetId={selectedAssetId}
            onDeleteAsset={handleDeleteAsset}
            onArchiveNotification={handleArchiveNotification}
            assetType={assetType}
            onMarkAsRead={handleMarkAsRead}
            onMarkAsUnread={handleMarkAsUnread}
          />
        )}
      </div>
      <div className="w-2/3 pl-4 border-l border-gray-300 dark:border-gray-600 overflow-y-auto">
        <AssetDetail
          asset={selectedAsset}
          assetType={assetType}
          onDeleteAsset={handleDeleteAsset}
          onArchiveNotification={handleArchiveNotification}
          onMarkAsRead={handleMarkAsRead}
          onMarkAsUnread={handleMarkAsUnread}
        />
      </div>
    </div>
  );
};

export default AssetsPage;
