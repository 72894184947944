import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHeader, Label } from "./SharedComponents";
import { faWordpress } from "@fortawesome/free-brands-svg-icons";

const WordPressPostForm = ({
  wordpressSiteUrl,
  setWordpressSiteUrl,
  postStatus,
  setPostStatus,
  publishDate,
  setPublishDate,
  handleWordPressPost,
  config,
  setIsExpanded,
  postTitle,
  setPostTitle,
  postContent,
  setPostContent,
  postExcerpt,
  setPostExcerpt,
  featuredImage,
  setFeaturedImage,
}) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
      <FormHeader
        title="WordPress Post Settings"
        onClose={() => setIsExpanded(false)}
      />
      <div className="space-y-4">
        <Button
          onClick={handleWordPressPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faWordpress} className="mr-2 text-xl" />
          Submit to WordPress
        </Button>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label tooltip="Select the WordPress site to post to">
              WordPress Site
            </Label>
            <Select
              value={wordpressSiteUrl}
              onValueChange={setWordpressSiteUrl}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select WordPress site" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="https://thatnurselife.com">
                  That Nurse Life
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label tooltip="Set the status of your post">Post Status</Label>
            <Select value={postStatus} onValueChange={setPostStatus}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select post status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="draft">Draft</SelectItem>
                <SelectItem value="publish">Publish</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <Label tooltip="Enter the title of your post">Post Title</Label>
          <Input
            type="text"
            value={postTitle}
            placeholder="Craft an attention-grabbing title (50-60 characters for SEO)"
            onChange={(e) => setPostTitle(e.target.value)}
            className="w-full"
          />
        </div>
        <div>
          <Label tooltip="Enter the main content of your post">
            Post Content
          </Label>
          <Textarea
            value={postContent}
            placeholder="Write your full article here. Include headings, paragraphs, and media."
            onChange={(e) => setPostContent(e.target.value)}
            className="w-full"
            rows={6}
          />
        </div>
        <div>
          <Label tooltip="Enter a brief excerpt of your post">
            Post Excerpt
          </Label>
          <Textarea
            value={postExcerpt}
            placeholder="Summarize your post in 2-3 compelling sentences (50-160 characters)"
            onChange={(e) => setPostExcerpt(e.target.value)}
            className="w-full"
            rows={3}
          />
        </div>
        <div>
          <Label tooltip="Enter the URL of the featured image">
            Featured Image URL
          </Label>
          <Input
            type="text"
            value={featuredImage}
            onChange={(e) => setFeaturedImage(e.target.value)}
            className="w-full"
          />
        </div>
        
        <div>
          <Label tooltip="Set the publish date and time">
            Publish Date (Optional)
          </Label>
          <DatePicker
            selected={publishDate}
            onChange={(date) => setPublishDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="w-full p-2 border rounded-md"
            placeholderText="Select date and time"
          />
        </div>
        <Button
          onClick={handleWordPressPost}
          className={`${config.color} w-full font-semibold`}
        >
          <FontAwesomeIcon icon={faWordpress} className="mr-2 text-xl" />
          Submit to WordPress
        </Button>
      </div>
    </div>
  );
};

export default WordPressPostForm;
