import React, { useEffect, useState, useCallback, memo, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
  fetchExecutionById, 
  clearCurrentExecution, 
  fetchExecutions, 
  fetchExecutionStatus,
  updateExecutionInList
} from '../slices/executionsSlice';
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { Pagination } from "../components/ui/pagination";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { ScrollArea } from "../components/ui/scroll-area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Badge } from "../components/ui/badge";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../components/ui/tooltip";
import { CheckCircle, XCircle, Clock, AlertTriangle, Search } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns'; 
import ExecutionDetails from './ExecutionPageComponents/ExecutionDetails';

const ExecutionListItem = memo(({ execution, isSelected, onSelect }) => (
  <Card
    className={`cursor-pointer transition-colors ${
      isSelected ? "bg-gray-900 text-white" : ""
    } mb-2`}
    onClick={() => onSelect(execution)}
  >
    <CardContent className="p-4">
      <div className="flex justify-between items-center">
        <h3 className="text-sm font-semibold">{execution.flow?.name}</h3>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Badge variant={execution.status === 'completed' ? 'success' : execution.status === 'failed' ? 'destructive' : 'default'}>
                {execution.status === 'completed' && <CheckCircle className="w-3 h-3 mr-1" />}
                {execution.status === 'failed' && <XCircle className="w-3 h-3 mr-1" />}
                {execution.status === 'running' && <Clock className="w-3 h-3 mr-1" />}
                {execution.status}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>Execution status</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <p className="text-xs text-muted-foreground mt-1">
        Started: {formatDistanceToNow(new Date(execution.startTime), { addSuffix: true })}
      </p>
      <p className="text-xs text-muted-foreground">
        Duration: {execution.duration ? `${(execution.duration / 1000).toFixed(2)}s` : 'N/A'}
      </p>
    </CardContent>
  </Card>
));

const ExecutionList = memo(({ executions, onSelectExecution, selectedExecutionId }) => (
  <div className="space-y-2 p-4">
    {executions.map((execution) => (
      <ExecutionListItem
        key={execution._id}
        execution={execution}
        isSelected={selectedExecutionId === execution._id}
        onSelect={onSelectExecution}
      />
    ))}
  </div>
));

const ExecutionPage = () => {
  const { executionId } = useParams();
  console.log('Execution ID:', executionId);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState('startTime');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20); // Assuming 10 items per page
    const [totalItems, setTotalItems] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [executionStatus, setExecutionStatus] = useState(null);
  
  const execution = useSelector((state) => state.executions.currentExecution);
  const { executions, totalExecutions } = useSelector((state) => state.executions);
  const { selectedTeam } = useSelector((state) => state.teams);

  const prevExecutionStatusRef = useRef();

  const fetchStatus = useCallback(() => {
    if (executionId) {
      dispatch(fetchExecutionStatus(executionId))
        .unwrap()
        .then((updatedExecution) => {
          if (updatedExecution.status !== 'running') {
            setIsPolling(false);
          } 
          if (updatedExecution.status !== prevExecutionStatusRef.current) {
            dispatch(updateExecutionInList(updatedExecution));
            prevExecutionStatusRef.current = updatedExecution.status;
          }
        });
    }
  }, [dispatch, executionId]);

  useEffect(() => {
    let intervalId;
    if (isPolling && execution?.status === 'running') {
      intervalId = setInterval(fetchStatus, 5000);
      setExecutionStatus(execution?.status);
    } else if (execution?.status !== 'running') {
      setIsPolling(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isPolling, fetchStatus, execution]);

  useEffect(() => {
    if (executionId) {
      setIsLoading(true);
      dispatch(fetchExecutionById(executionId))
        .unwrap()
        .then((fetchedExecution) => {
          setIsLoading(false);
          if (fetchedExecution.status === 'running') {
            setIsPolling(true);
          }
          prevExecutionStatusRef.current = fetchedExecution.status;
        })
        .catch((error) => {
          console.error('Error fetching execution:', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsPolling(false);
    }
  
    return () => {
      dispatch(clearCurrentExecution()); // Clear execution when component unmounts
    };
  }, [dispatch, executionId]);
  

  useEffect(() => {
    dispatch(fetchExecutions({ 
      page: currentPage, 
      limit: itemsPerPage, 
      teamId: selectedTeam, 
      search: searchTerm, 
      status: statusFilter, 
      sortBy, 
      sortOrder 
    }));
    setTotalItems(totalExecutions)
    console.log('Executions:', executions);

    if (executionId) {
      dispatch(fetchExecutionById(executionId));
    }

    return () => {
      dispatch(clearCurrentExecution());
    };
  }, [dispatch, executionId, currentPage, itemsPerPage, selectedTeam, searchTerm, statusFilter, sortBy, sortOrder]);

  const handleSelectExecution = useCallback((execution) => {
    if (execution._id !== executionId) {  
      setIsLoading(true);
      dispatch(clearCurrentExecution());  // Clear current state before navigation
      navigate(`/execution/${execution._id}`); // Navigate to the new execution's page
    }
  }, [executionId, navigate, dispatch]);
  
  

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredExecutions = executions.filter(exec => {
    const matchesSearch = exec.flow?.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter === 'all' || exec.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const sortedExecutions = [...filteredExecutions].sort((a, b) => {
    if (sortBy === 'startTime') {
      return sortOrder === 'asc' 
        ? new Date(a.startTime) - new Date(b.startTime)
        : new Date(b.startTime) - new Date(a.startTime);
    } else if (sortBy === 'duration') {
      return sortOrder === 'asc' ? a.duration - b.duration : b.duration - a.duration;
    }
    return 0;
  });

  return (
    <div className="flex h-[calc(100vh-2rem)]">
      <div className="w-1/3 border-r flex flex-col">
        <div className="flex-grow overflow-y-auto">
          <div className="p-4 py-0">
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-bold">Executions</h1>
            </div>
            <div className="flex space-x-2 mb-4">
              <div className="flex-grow">
                <Input
                  type="text"
                  placeholder="Search executions..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full"
                  icon={<Search className="w-4 h-4" />}
                />
              </div>
              <Select value={statusFilter} onValueChange={setStatusFilter}>
                <SelectTrigger className="w-[150px]">
                  <SelectValue placeholder="Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Statuses</SelectItem>
                  <SelectItem value="completed">Completed</SelectItem>
                  <SelectItem value="failed">Failed</SelectItem>
                  <SelectItem value="running">Running</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex space-x-2 mb-4">
              <Select value={sortBy} onValueChange={setSortBy}>
                <SelectTrigger className="w-[150px]">
                  <SelectValue placeholder="Sort By" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="startTime">Start Time</SelectItem>
                  <SelectItem value="duration">Duration</SelectItem>
                </SelectContent>
              </Select>
              <Select value={sortOrder} onValueChange={setSortOrder}>
                <SelectTrigger className="w-[150px]">
                  <SelectValue placeholder="Sort Order" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="asc">Ascending</SelectItem>
                  <SelectItem value="desc">Descending</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <ExecutionList
            executions={sortedExecutions}
            onSelectExecution={handleSelectExecution}
            selectedExecutionId={executionId}
          />
        </div>
        <div className="p-4 border-t">
          <div className="flex justify-between items-center">
            <Select
              value={itemsPerPage.toString()}
              onValueChange={(value) => setItemsPerPage(Number(value))}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Items per page" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="10">10 per page</SelectItem>
                <SelectItem value="20">20 per page</SelectItem>
                <SelectItem value="50">50 per page</SelectItem>
                <SelectItem value="100">100 per page</SelectItem>
              </SelectContent>
            </Select>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              paginate={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="w-2/3 h-full overflow-y-auto">
        {isLoading ? (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">Loading execution details...</p>
          </div>
        ) : execution ? (
          <ExecutionDetails
            execution={execution}
            isPolling={isPolling}
            setIsPolling={setIsPolling}
          />
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">Select an execution to view details</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExecutionPage;