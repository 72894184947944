import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { ScrollArea } from "../../components/ui/scroll-area";
import RealTimeFlowExecution from '../../components/RealTimeFlowExecution';
import { CheckCircle, XCircle, Clock, AlertTriangle, Search, Copy, ChevronDown, ChevronUp, Ban } from 'lucide-react';
import { format, formatDistanceToNow } from 'date-fns';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import AIImageDisplay from '../../pages/FlowFormComponents/AIImageDisplay';
import { useToast } from "../../components/ui/use-toast";
import TakeToChatButton from '../../components/TakeToChatButton';

const ExecutionDetails = ({ execution, isPolling, setIsPolling  }) => {

  const { toast } = useToast();

    const [preservedSteps, setPreservedSteps] = useState([]);
  
    useEffect(() => {
      if (execution && execution._id) {
        // Ensure preservedSteps is updated based on the actual execution, not just the flow
        setPreservedSteps(execution.flow.steps || []);
        console.log('ExecutionDetails execution:', execution);
      }
    }, [execution]);
  
    if (!execution) {
      return <div>No execution data available.</div>;
    }
  
    const getStatusIcon = (status) => {
      switch (status) {
        case 'completed':
          return <CheckCircle className="text-green-500" />;
        case 'failed':
          return <XCircle className="text-red-500" />;
        case 'running':
          return <Clock className="text-blue-500" />;
        case 'skipped':
          return <Ban className="text-gray-500" />;
        default:
          return <AlertTriangle className="text-yellow-500" />;
      }
    };
  
    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? 'Invalid Date' : format(date, 'PPpp');
    };
  
    const formatDuration = (duration) => {
      if (!duration) return 'N/A';
      const seconds = Math.floor(duration / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
    };

    const copyToClipboard = (text) => {
      const stringifiedText = stringifyContent(text);
      navigator.clipboard.writeText(stringifiedText).then(() => {
        toast({
          title: "Copied to Clipboard",
          description: "The output has been copied to your clipboard.",
          duration: 2000,
        });
      }).catch((err) => {
        toast({
          title: "Copy Failed",
          description: "Failed to copy text: " + err,
          variant: "destructive",
          duration: 3000,
        });
      });
    };

    const stringifyContent = (content) => {
      if (typeof content === 'string') {
        return content;
      }
      if (typeof content === 'object') {
        return JSON.stringify(content, null, 2);
      }
      return String(content);
    };
  
    const renderStepOutput = (step, stepType) => {
      if (stepType === 'generateAIImage' && typeof step.output === 'string' && step.output.startsWith('http')) {
        return <AIImageDisplay imageUrl={step.output} />;
      }
  
      return (
        <div className="mt-2 relative">
          <div className="bg-gray-100 p-4 rounded mt-1 overflow-x-auto">
            <ReactMarkdown
              className='markdown-content'
              remarkPlugins={[remarkGfm]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {typeof step.output === 'string' ? step.output : JSON.stringify(step.output, null, 2)}
            </ReactMarkdown>
          </div>
          <Button
            variant="outline"
            size="sm"
            className="absolute bottom-2 right-2 z-10"
            onClick={() => copyToClipboard(step.output)}
          >
            <Copy className="h-4 w-4" />
            
          </Button>
        </div>
      );
    };
  
    return (
      <div className="container mx-auto px-4 py-0">
        <h1 className="text-2xl font-bold mb-4">Execution Details</h1>
        <Card className="mb-4">
          <CardHeader>
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Flow: {execution.flow?.name || 'N/A'}</h2>
              <div className="flex items-center space-x-2">
                {getStatusIcon(execution.status)}
                <span className="capitalize">{execution.status}</span>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p><strong>Execution ID:</strong> {execution._id}</p>
                <p><strong>Start Time:</strong> {formatDate(execution.startTime)}</p>
                <p><strong>End Time:</strong> {formatDate(execution.endTime)}</p>
              </div>
              <div>
                <p><strong>Duration:</strong> {formatDuration(execution.duration)}</p>
                <p><strong>Flow ID:</strong> {execution.flow?._id || 'N/A'}</p>
              </div>
            </div>
          </CardContent>
        </Card>
        {execution && (
  <RealTimeFlowExecution 
    execution={execution}
    isPolling={isPolling}
    setIsPolling={setIsPolling}
  />
)}
        <h2 className="text-2xl font-semibold mb-4">Step Results</h2>
        
        {Array.isArray(execution.stepResults) && execution.stepResults.length > 0 ? (
          execution.stepResults.map((step, index) => {
            const preservedStep = preservedSteps[index] || {};
            return (
              <Card key={step._id || index} className={`mb-4 ${step.status === 'skipped' ? 'bg-gray-300' : ''}`}>
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <h3 className="text-xl font-semibold">
                      Step {index + 1}: {preservedStep.name || preservedStep.type || 'Unknown Step'}
                    </h3>
                    <div className="flex items-center space-x-2">
                      {getStatusIcon(step.status)}
                      <span className="capitalize">{step.status}</span>
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <p className='mb-2'><strong>Instructions:</strong> {preservedStep.prompt || preservedStep.description || 'No instructions available'}</p>
                  <p><strong>Duration:</strong> {formatDuration(step.duration)}</p>
                  {step.output && renderStepOutput(step, preservedStep.type)}
                  {step.error && (
                    <div className="mt-2 p-4 bg-red-100 text-red-700 rounded">
                      <strong>Error:</strong> {step.error}
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })
        ) : (
          <p>No step results available.</p>
        )}
  
  
  
        {isPolling ? (
          <div className="mt-4">
            <p className="text-blue-500">Polling for updates...</p>
          </div>
        ) : (
          <div className='absolute right-4 bottom-4'>
            <TakeToChatButton executionId={execution._id} />
          </div>
        )}
      </div>
    );
  };

export default ExecutionDetails;