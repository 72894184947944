// src/slices/genericItemsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItems as fetchItemsApi } from '../services/api';

export const fetchItems = createAsyncThunk(
    'genericItems/fetchItems',
    async (modelName, { rejectWithValue }) => {
      try {
        const response = await fetchItemsApi(modelName);
        
        // Sort the items alphabetically by name
        const sortedItems = response.sort((a, b) => 
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
  
        return { modelName, items: sortedItems };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

const genericItemsSlice = createSlice({
  name: 'genericItems',
  initialState: {
    items: {},
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items[action.payload.modelName] = action.payload.items;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default genericItemsSlice.reducer;